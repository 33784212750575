<template>
    <div class="histotyDialog">
        <el-dialog
            :visible.sync="visible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
            class="chatViewDialog"
            width="70%">
                <div class="dialog-header">
                    <div class="dw-logo">
<!--                        <svg-icon icon-class="dwLogo"></svg-icon>-->
                    </div>
                    <div class="dialog-title">
                        <div class="row1 blod60">{{ dialogTitle }}</div>
                    </div>
                    <div class="close" @click="closeDialog()">
                        <svg-icon class="close-icon" icon-class="close"></svg-icon>
                    </div>
                </div>
                <history v-bind="$attrs"></history>
        </el-dialog>
    </div>
</template>

<script>
import history from './history'
export default {
    components: {
        history
    },
    props: {
        historyVisible: {
            type: Boolean,
            default: false
        },
        toSendMsg: {
            type: Object,
            default: () => {},
        },
        other: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        visible: {
            get() {
                return this.historyVisible;
            },
            set(val) {
                this.$emit('update:historyVisible', val)
            }
        }
    },
    data() {
        return {
            dialogTitle: "Chatting history",
        };
    },
    created() {},
    mounted: function () {},
    methods: {
        closeDialog() {
            this.visible = false;
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.histotyDialog {
    .chatViewDialog {
        .el-dialog {
            height: 100vh;
            min-height: 750px;
            margin: 0 auto 0px;
        }
        .el-dialog__header {
            padding: 0;
        }
        .el-dialog__body {
            height: 100%;
            padding: 0;
        }
        .el-dialog__close {
            display: none;
        }
        .dialog-header {
            height: 105px;
            padding: 20px;
            background-color: $mainColor;
            display: flex;
            .dw-logo {
                width: 70px;
                height: 70px;
                padding: 15px;
                flex: 1;
                .svg-icon {
                    width: 70px;
                    height: 70px;
                    color: #fff;
                }
            }
            .close {
                width: 50px;
                height: 50px;
                margin-top: 30px;
                margin-left: 100px;
                flex: 1;
                .close-icon {
                    width: 50px;
                    height: 50px;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .dialog-title {
                color: #fff;
                margin-left: 90px;
                text-align: left;
                flex: 10;
                .row1 {
                    margin-top: 5px;
                }
                .row2 {
                    margin-top: 14px;
                    color: $fontColor1;
                }
            }
        }
    }
}
</style>
