<template>
    <div class="history">
        <div class="booking-left-menu" v-if="other">
            <div class="booking-button button" :class="{'is-check': (isCheck == '')}" @click="isCheckFun('')">
                {{$t('lang.NText7')}}
            </div>
            <div class="quotation-button button" :class="{'is-check': (isCheck == '20')}" @click="isCheckFun('20')">
                {{$t('lang.NText171')}}
            </div>
            <div class="quotation-button button" :class="{'is-check': (isCheck == '30')}" @click="isCheckFun('30')">
                {{$t('lang.NText172')}}
            </div>
        </div>
        <div class="dialog-main">
            <div class="TrackingLeftSearch">
                <el-input class="searchInput" clearable v-model="searchInput" @keyup.enter.native="searchClick()"></el-input>
                <div class="searchBox" @click="searchClick()">
                    <svg-icon icon-class="search" class="svg-icon" style="color: #fff"/>
                </div>
                <p v-if="iShow">{{$t('lang.BText43')}} {{list.length}} {{$t('lang.NText173')}} </p>
            </div>
            <div class="ChatContentBox">
                <div class="contentList" v-for="item in list" :key="item.id">
                    <div class="svg">
                        <div class="svgBox">
                            <svg-icon icon-class="userHead" class="svg-icon" />
                        </div>
                    </div>
                    <div class="content">
                        <div class="name">{{item.sender}}</div>
                        <div class="text" v-html="item.content"></div>
                        <div class="time">{{item.sendTime}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        toSendMsg: {
            type: Object,
            default: () => {}
        },
        other: {
            type: Boolean,
            default: () => true
        },
        orderNum: {
            type: [Number, String],
            default: () => ''
        },
        orderType: {
            type: [Number, String],
            default: () => ''
        },
    },
    data() {
        return {
            iShow: false,
            isCheck: '',
            list: [],
            searchInput: '',
            pageNo: 1,
            pageSize: 2000,
            recordType: '',
            recordNo: '',
        };
    },
    created() {

    },
    mounted: function () {},
    methods: {
        isCheckFun(str) {
            if(this.isCheck == str) return;
            this.list = [];
            this.iShow = false;
            this.isCheck = str;
        },
        searchClick() {
            this.chatList();
        },
        // 聊天信息列表查询
        chatList() {
            this.refreshChatHistory();
            // this.getChatHistory();
        },
        refreshChatHistory() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let data = {
                userId: JSON.parse(sessionStorage.getItem('@userInfo'))['id']
            }
            this.$ajax.cPost(this.source.account_api.refreshChatHistory, data).then((res) => {
                if (res.data.result == "true") {
                    this.getChatHistory();
                } else {
                    loading.close();
                    this.$notify({
                        title: res.data.message,
                        type: "error",
                        offset: 100,
                    });
                }
            });
        },
        getChatHistory() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let data = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                queryTag: this.isCheck,
                recordType: this.orderType,
                recordNo: this.orderNum,
                contentKeyword: this.searchInput,
                // startTime: '',
                // endTime: '',
            }
            this.$ajax.cPost(this.source.account_api.getChatHistory, data).then((res) => {
                if (res.data.result == "true") {
                    this.iShow = true;
                    this.list = res.data.data.item
                } else {
                    this.$notify({
                        title: res.data.message,
                        type: "error",
                        offset: 100,
                    });
                }
                loading.close();
            });
        },
        queryTagFun(item) {
            let str;
            switch (item) {
                case "10":
                    str = "";
                    break;
                case "20":
                    str = "";
                    break;
                case "30":
                    str = "rich";
                    break;
            }
            return str;
        }
    },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.history {
    height: calc(100% - 145px);
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        padding: 0;
    }
    .el-dialog__close {
        display: none;
    }
    .booking-left-menu {
        position: absolute;
        top: 200px;
        left: -44px;
        .button {
            width: 146px;
            height: 60px;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg); /* IE 9 */
            -moz-transform: rotate(90deg); /* Firefox */
            -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
            -o-transform: rotate(90deg);
            font-size: 20px;
            border: 2px solid $mainColor;
            line-height: 60px;
            background-color: #fff;
            color: $mainColor;
            cursor: pointer;
            transition: .5s;
        }
        .booking-button {
            margin-top: 30px;
        }
        .quotation-button {
            margin-top: 100px;
        }
        .chatHistory-button {
            margin-top: 100px;
        }
        .button:hover,
        .is-check {
            background-color: $mainColor;
            color: $background2;
        }
    }
    .dialog-main {
        width: 860px;
        height: calc(100% - 60px);
        padding-bottom: 10px;
        padding-top: 50px;
        margin: auto;
        background-color: #F9F9F9;
        .TrackingLeftSearch {
            width: 100%;
            margin: 0 auto;
            height: 110px;
            .searchInput{
                width: 72%;
                .el-input__inner {
                    height: 64px;
                    border: 2px solid $mainColor;
                }
            }
            .searchBox{
                width: 64px;
                height: 64px;
                background-color: #132569;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                line-height: 63px;
                cursor: pointer;
                .svg-icon{
                    font-size: 30px;
                }
            }
            p{
                font-size: 16px;
                color: #999;
            }
        }
        .ChatContentBox{
            width: 100%;
            height: calc(100% - 120px);
            // max-height: calc(100vh - 270px);
            // max-height: calc(100% - 500px);
            overflow: auto;
            &::-webkit-scrollbar {
                width: 5px !important;
                height: 5px !important;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: rgba(0,0,0,0.1);
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: rgba(0,0,0,0.1);
            }
            box-sizing: border-box;
            border-top: 1px solid #ccc;
            .contentList{
                text-align: left;
                min-height: 75px;
                height: auto;
                .svg, .content{
                    display: inline-block;
                    vertical-align: top;
                }
                .svg{
                    width: 88px;
                    height: 75px;
                    text-align: center;
                    position: relative;
                    .svgBox{
                        background-color: #fff;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: 1px solid #ccc;
                        box-sizing: border-box;
                        position: absolute;
                        top: 22%;
                        left: 33%;
                        padding-top: 6px;
                        .svg-icon{
                            font-size: 24px;
                        }
                    }

                }
                .content{
                    width: calc(100% - 118px);
                    min-height: 75px;
                    height: auto;
                    box-sizing: border-box;
                    margin-right: 30px;
                    border-bottom: 1px solid #ccc;
                    padding-top: 31px;
                    position: relative;
                    .name{
                        color: #999;
                        font-size: 16px;
                    }
                    .text{
                        color: #132569;
                        font-size: 16px;
                        width: 83.5%;
                        padding: 10px 0;
                        p{
                            margin-top: 0px;
                            margin-bottom: 0px;
                        }
                    }
                    .time{
                        position: absolute;
                        top: 28px;
                        right: 1px;
                        font-size: 14px;
                        color: #999;
                    }
                }
            }
        }
    }
}
</style>