<template>
  <div class="dwChat">
    <div class="chatContent" @scroll="scrollEvent" ref="chatContent">
      <chat-news :newsArr="newsArr" :isMore="isMore" :loading="loading" @more="more"></chat-news>
    </div>
    <div class="sendMain">
      <quill-editor ref="myQuillEditor" class='editor' v-model="content" :options="editorOption" @change="onEditorChange($event)" @ready="onEditorReady($event)" />
      <el-popover placement="top-start" popper-class="emoBox" width="emoBox" trigger="click" class="emoBox">
          <div class="emotionList">
              <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item,index) in faceList" :key="index" class="emojiItem">{{item}}</a>
          </div>
        <svg-icon slot="reference" icon-class="emoji" class="emoji"></svg-icon>
      </el-popover>
      <svg-icon icon-class="file" class="file"></svg-icon>
      <svg-icon icon-class="chat" class="chat" @click="chatClick()"></svg-icon>
      <el-button  class="button" type="primary" @click="btnSend()">Send</el-button>
    </div>

  </div>
</template>

<script>
import chatNews from './chatNews'
import VueEmoji from "emoji-vue";
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
const toolbarOptions = [
    ['link'], // 链接、图片、视频-----['link', 'image', 'video']
];
export default {
  data() {
    return {
      webScoket: '',
      emojiData: require('../../assets/emoji.json'),
      faceList: [],
      newsArr: [],
      textarea: "",
      content: "",
      pageNo: 1,
      pageSize: 20,
      allPage: 0,
      isMore: true,
      loading: false,
      isChatClick: false,
      isScrollToBottom: true,
      lastData: '',
      timer: null,
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
          // ImageExtend: {
          //   loading: true,
          //   name: "img",
          //   action: "https://api.de-well.com/a/file/upload",
          //   response: (res) => {
          //     return res.info;
          //   },
          // },
          // toolbar: {
          //   container: toolbarOptions,
          //   handlers: {
          //     image: function (value) {
          //       if (value) {
          //         document.querySelector("#upload").click();
          //         this.ishowFile = false;
          //       } else {
          //         this.quill.format("image", false);
          //       }
          //     },
          //   },
          // },
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: (range, context) => {
                  this.saveMsgEnter();
                },
              },
            },
          },
        },
        theme: "snow",
        placeholder: "Your message ...",
        editor: null
      },
    };
  },
  props: {
    toSendMsg: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    // VueEmoji,
    quillEditor,
    chatNews,
  },
  computed: {
      editor() {
          return this.$refs.myQuillEditor.quill;
      },
  },
  created() {
      if(this.timer) {
          clearInterval(this.timer);
      }
      this.chatList('bottom');
  },
  updated() {

  },
  destroyed() {
    if(this.timer) {
        clearInterval(this.timer);
    }
  },
  methods: {
    getArrDifference(array1, array2) {
			let result = [];
			for (let i = 0; i < array2.length; i++) {
				let obj = array2[i];
				let num = obj.id;
				let flag = false;
				for (let j = 0; j < array1.length; j++) {
					let aj = array1[j];
					let n = aj.id;
					if (n == num) {
						flag = true;
						break;
					}
				}
				if (!flag) {
					result.push(obj);
				}
			}
      return result;
    },
    scrollEvent(e) {
        // 底部
        if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight - 100 ) {
            this.isScrollToBottom = true;
        } else if( e.srcElement.scrollTop == 0) {
        // 头部
            this.isScrollToBottom = false;
            // this.more();
        } else {
          // 中间
            this.isScrollToBottom = false;
        }
    },
    more() {
        this.loading = true;
        this.pageNo++;
        this.chatList('top')
    },
    // 聊天信息列表查询
    chatList(order, msgType) {
      let data = {
        cid: this.toSendMsg.id,
        msgType: msgType? msgType: '',
        // sendUserId: this.toSendMsg.bid,
        btype: this.toSendMsg.btype,
        bid: this.toSendMsg.bid,
        // bno: (this.toSendMsg.btype == '0')? '':this.toSendMsg.bid,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        msgTime:this.lastData,
      }
      this.$ajax.cPost(this.source.account_api.chatList, data).then((res) => {
          if (res.data.result == "true") {
              if(res.data.list.length > 0) {
                  res.data.list.forEach((req, index) => {
                      if(index == 0) {
                          req.top = 'one' + this.pageNo
                      };
                  });
              }
              this.allPage = Math.ceil(res.data.allCount / 20);
              if(this.newsArr.length == 0) {
                  this.newsArr = res.data.list;
                  if(res.data.list.length) {
                    this.lastData = res.data.list[res.data.list.length - 1].msgTime;
                  }
                  this.scrollToBottom();
                  this.isMore = (this.allPage > 1)? true:false;
              } else {
                  this.loading = false;
                  this.isMore = (this.pageNo < this.allPage)? true:false;
                  let arr = this.getArrDifference(this.newsArr, res.data.list);
                  if(arr.length > 0) {
                      this.newsArr = (order == 'bottom')? [...this.newsArr, ...arr]:[...arr, ...this.newsArr]
                      if(this.isScrollToBottom) {
                          this.scrollToBottom();
                      }
                  }
                  if(this.newsArr.length) {
                    this.lastData = this.newsArr[this.newsArr.length - 1].msgTime;
                  }
                  this.$nextTick(() => {
                      if(order == 'top') {
                          let chatContent = this.$el.querySelector(".chatContent");
                          chatContent.scrollTop = this.$el.querySelector("#one" + (this.pageNo - 1)).offsetTop - 50;
                      }
                  })
              }
              this.timer = setInterval((res => {
                  this.chatList('bottom')
              }), 3000)
          } else {
              this.$notify({
                  title: res.data.message,
                  type: "error",
                  offset: 100,
              });
          }
      });
    },
    initWebScoket() {
      this.webScoket = new webScoket('ws://xxxxx');
      this.webScoket.onopen = this.webScoketOpen;
      this.webScoket.onmessage = this.webScoketMessage;
      this.webScoket.onerror = this.webScoketError;
      this.webScoket.onclose = this.webScoketClose;
    },
    webScoketOpen() {
      console.log('连接成功')
    },
    webScoketMessage(e) {

    },
    webScoketError() {
      console.log('连接失败')
    },
    webScoketClose() {

    },
    faceListData() {
      for(let i in this.emojiData){
        this.faceList.push(this.emojiData[i].char);
      }
    },
    onEditorChange({ editor, html, text }) {
      // console.log(this.content);
    },
    onEditorReady(quill) {
        // if(this.$route.query.sid) {
        //     this.checkLoginStatus();
        // }
    },
    btnSend() {
      this.saveMsgEnter();
    },
    saveMsgEnter() {
        if(this.content) {
          this.sendMsg()
          // this.newsArr.push(this.content);
          // this.content = '';
          // this.scrollToBottom();
          // // this.webScoket.send(this.content)
        } else {
            // this.tipsContent = true;
            console.log('不能发送空白');
        }
    },
    sendMsg() {
      let data = {
        bid: this.toSendMsg.bid,
        btype: this.toSendMsg.btype,
        name: JSON.stringify({}),
        msgType: '10',
        msgText:  "<!--HTML-->" + this.content,
        sendUserId: this.toSendMsg.userId,
        sendUserName: this.toSendMsg.userName,
        cid: this.toSendMsg.id,
        msgTime: Date.parse(new Date()),
      }
      this.$ajax.cPost(this.source.account_api.sendMsg, data).then((res) => {
          if (res.data.result == "true") {
            this.newsArr.push(res.data.data)
            this.content = '';
            this.scrollToBottom();
          } else {
              this.$notify({
                  title: res.data.message,
                  type: "error",
                  offset: 100,
              });
          }
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        let container = this.$el.querySelector(".chatContent");
        container.scrollTop = container.scrollHeight;
      });
    },
    setSelectionRange(input, selectionStart, selectionEnd) {
      if (input.setSelectionRange) {
        input.focus()
        input.setSelectionRange(selectionStart, selectionEnd)
      } else if (input.createTextRange) {
        const range = input.createTextRange()
        range.collapse(true)
        range.moveEnd('character', selectionEnd)
        range.moveStart('character', selectionStart)
        range.select()
      }
    },
    getEmo(index){
      this.content += `<span style="display: inline-block;">${this.faceList[index]}</span>`
      console.log();
      this.$nextTick(() => {
      let put = this.$refs.myQuillEditor[0]
      let len = this.content.length
      this.setSelectionRange(put, len, len)
        // console.log(el)
        // el.selectionStart += this.faceList[index].length;
        this.$refs.myQuillEditor.focus()
      })
        // var textArea=document.getElementById('textarea');
        // function changeSelectedText(obj, str) {
        //   cons
        //   if (window.getSelection) {
        //     // 非IE浏览器
        //     textArea.setRangeText(str);
        //     // 在未选中文本的情况下，重新设置光标位置
        //     textArea.selectionStart += str.length;
        //     textArea.focus()
        //   } else if (document.selection) {
        //     // IE浏览器
        //     obj.focus();
        //     var sel = document.selection.createRange();
        //     sel.text = str;
        //   }
        // }
        // changeSelectedText(textArea,this.faceList[index]);
        // this.textarea=textArea.value;// 要同步data中的数据
      },
      chatClick() {
        this.$emit('chatClick', this.toSendMsg)
      }
  },
  mounted() {
    this.faceListData()
  }
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.emoBox{
    height:200px;
    width:400px;
    overflow:auto;
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(0,0,0,0.1);
    }
    .emotionList{
      .emojiItem{
        width:10%;
        font-size:20px;
        text-align:center;
      }
      .emojiItem {
          text-decoration: none;
      }
      .emojiItem:link {
          text-decoration: none;
      }
      .emojiItem:visited {
          text-decoration: none;
      }
      .emojiItem:hover {
          text-decoration: none;
      }
      .emojiItem:active {
          text-decoration: none;
      }
    }
}
.dwChat {
  width: 100%;
  height: 100%;
  .chatContent{
    width: 100%;
    height: calc(100% - 132px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(0,0,0,0.1);
    }
  }
  .sendMain {
    background-color: #fff;
    height: 132px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .el-textarea__inner{
      padding: 20px 120px 20px 20px;
    }
    .svg-icon {
      color: $fontColor3;
      height: 24px;
      width: 24px;
      cursor: pointer;
      position: absolute;
    }
    .emoji{
      top: 12px;
      right: 95px;
      z-index: 11;
    }
    .file{
      top: 12px;
      right: 55px;
    }
    .chat{
      top: 12px;
      right: 15px;
      z-index: 11;
    }
    .button {
      margin-top: 20px;
      position: absolute;
      right: 13px;
      bottom: 16px;
      width: 110px;
      height: 48px;
      line-height: 48px;
      font-size: 18px;
    }
    .editor {
      height: 100%;
      .ql-toolbar{
        border: none;
        padding: 0;
        text-align: right;
        height: 45px;
        line-height: 45px;
        position: relative;
        width: 20%;
        margin-left: 503px;
        z-index: 10;
        .ql-formats{
          opacity: 0;
          margin-right: 50px;
          button{
            width: 35px;
            height: 30px;
          }
        }
      }
      .ql-container{
        border: none;
        height: 132px;
        width: 100%;
        position: absolute;
        top: 0;
        .ql-editor{
          box-sizing: border-box;
          padding-right: 130px;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 5px !important;
              height: 5px !important;
            }
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background-color: rgba(0,0,0,0.1);
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 6px rgba(0,0,0,.3);
              background-color: rgba(0,0,0,0.1);
            }
        }
      }
    }
  }
}
</style>
