import Vue from 'vue'
import Router from 'vue-router'
import routerConfig from './routeConfig'
import $session from '../utils/storageManager/storageManager'
import global from "../utils/global";
import util from '../utils/util'

Vue.use(Router)

let contextPath = '';
let router = new Router({
  mode: 'hash',
  // mode: 'history',
  // base: contextPath || '/',
  routes: routerConfig.routeConfig,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const resetSetItem = function (key, newVal) {
  if (key === 'tabList' || key === 'menuList' || key === 'secondList') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

router.beforeEach((to, from, next) => {
  if(to['fullPath'].indexOf('openType') > -1) {
    // let url = to['fullPath'];
    // let arr = util.getUrlParam(url);
    // let str = url.substring(1, url.indexOf('?'));
    // router.replace({name: str });
    this.$router.replace('/login')
  }
  $session.session("menuList").get((value) => {
    let menuList = value;
    if (menuList && menuList.forEach.length > 0) {
      sessionStorage.setItem('routerState', false)
      menuList.forEach((item, index) => {
        let secondMenu = item.childList;
        if (secondMenu.length == 0 && item.menuUrl == to.path) {
          resetSetItem('tabList', JSON.stringify(secondMenu));
          return
        }
        if (secondMenu.length > 0) {
          secondMenu.forEach((menu2, index2) => {
            if (to.path.indexOf("Settings") > 0) {
              if (menu2.childList.length == 0 && menu2.menuUrl == to.path) {
                resetSetItem('tabList', JSON.stringify(menu2.childList));
              } else if (menu2.childList.length > 0) {
                menu2.childList.forEach((menu3, index3) => {
                  if (menu3.menuUrl == to.path) {
                    resetSetItem('tabList', JSON.stringify(menu2.childList));
                    resetSetItem('secondList', JSON.stringify(secondMenu));
                  }
                })
              }
            } else {
              if (menu2.menuUrl == to.path) {
                resetSetItem('tabList', JSON.stringify(secondMenu));
              }
            }
          })
        }
      });
    } else {
      sessionStorage.setItem('routerState', true)
    }
  });
  next()
})
export default router
