<template>
  <el-dialog
    :visible.sync="showCheckBox"
    class="dwCheckBoxDialog"
    width="35%"
    @close="closeDialog"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div class="close" @click="Cancel()">
      <svg-icon icon-class="close"></svg-icon>
    </div>
    <div class="checkBoxMain">
      <slot></slot>
    </div>
    <div class="dialog-bottom">
      <el-button type="info" @click="Cancel()" class="samll-list-info">{{
        CancelButton
      }}</el-button>
      <el-button type="primary" class="samll-list-primary" :loading="loading" @click="Save()">{{
        primaryButton
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    CancelButton: {
      type: String,
      default: "Cancel",
    },
    primaryButton: {
      type: String,
      default: "Save",
    },
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    Cancel() {
      this.$emit("Cancel");
    },
    Save() {
      
      this.$emit("Save");
    },
    Delete() {
      this.showDelect = true;
    },
    closeDialog() {},
  },
};
</script>

<style lang='scss'>
@import "../../assets/base.scss";
.dwCheckBoxDialog {
  top: 30% !important;
  font-family: "montserratregular";
  .el-dialog__header {
    display: none;
  }
  .checkBoxMain {
    font-size: 30px;
    padding: 20px 10%;
    color: $mainColor;
    line-height: 40px;
    text-align: left;
    word-break: normal;
  }
  .close {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
  }
  .svg-icon {
    height: 30px;
    width: 30px;
    fill: $mainColor;
    stroke: $mainColor;
  }
  .line1 {
    margin-bottom: 10px;
  }
  .line2 {
    margin: 20px 0;
  }
  .dialog-bottom {
    margin-top: 20px;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    .samll-list-info {
      border: 2px solid $mainColor;
      color: $mainColor;
      font-size: 30px;
    }
  }
}
</style>