<template>
  <div class="kHeader">
    <div class="kHeader-left">
<!--      <div class="menuIcon" @click="showMenu()">-->
<!--        <div class="menuIcon1">-->
<!--          <svg-icon icon-class="tab" />-->
<!--        </div>-->
<!--      </div>-->
      <div class="titleDiv">
        <div class="title" v-if="menu[0] == 'Dashboard'">Link Me</div>
        <div class="title" v-else-if="$i18n.locale == 'zh_cn'">{{menuZh}}</div>
        <div class="title" v-else>{{ menu[0] }}</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">
            <span @click="toIndex">Home</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            :key="index"
            v-for="(item, index) in menu"
            :class="{ fourTree: index >= 1 }"
            v-show="index < 2"
            v-if="menu[0] != 'Dashboard'"
            ><span>{{ item }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="dashboardTitle" v-show="menu[0] == 'Dashboard'">
        <div class="dashboardTitle-line1">Link Tech</div>
        <div class="dashboardTitle-line2">
          intelligent digital transportation platform
        </div>
      </div>
    </div>

    <div class="kHeader-right">
      <div class="menuIcon">
        <!-- <div class="search">
          <input
            placeholder="HBL No. / FBA No. / CTN No. / PO No."
            v-model="input5"
            class="input-with-select"
          />
          <button class="search-button" @click="search()">
            <svg-icon
              icon-class="search"
              class="svg-icon"
              style="color: #fff"
            />
          </button>
        </div> -->
        <div class="icon icon1" @click="openTracking()">
          <svg-icon icon-class="location" class="svg-icon" />
        </div>
        <div class="icon icon2" @click="openTask()">
          <svg-icon icon-class="todo" class="svg-icon" />
        </div>
        <div class="icon icon3" @click="openNotification()">
          <el-badge :value="num" :max="99" class="item" :hidden="num == 0">
            <svg-icon icon-class="remind" class="svg-icon" />
          </el-badge>
        </div>
        <div class="icon icon4" @click="showModel()">
          <svg-icon icon-class="userHead" class="svg-icon" />
        </div>
      </div>
    </div>
    <!-- <div class="mask" v-show="menuCard || rightCard"></div> -->
    <el-drawer
      title="我是标题"
      :visible.sync="menuCard"
      :with-header="false"
      direction="ltr"
      custom-class="left"
    >
      <div class="menuIcon2" @click="closeMask()">
        <svg-icon icon-class="close" class="svg-icon" />
      </div>
      <header-left
        @closeMask="closeMask()"
        v-bind="$attrs"
        @menuInfo="menuInfo"
        @activeIndexFun="activeIndexFun"
      ></header-left>
    </el-drawer>
    <el-drawer
      title="我是标题"
      :visible.sync="rightCard"
      :with-header="false"
      custom-class="right"
    >
      <header-right
        class="headerRight"
        @closeMask="closeMask"
        :userInfo="userInfo"
        @menuInfo="menuInfo"
      ></header-right>
    </el-drawer>
  </div>
</template>

<script>
import HeaderLeft from "./header-left.vue";
import HeaderRight from "./header-right.vue";
export default {
  components: {
    HeaderLeft,
    HeaderRight,
  },
  data() {
    return {
      input5: null,
      rightCard: false,
      showMask: false,
      menuCard: false,
      closeMenu: false,
      userInfo: {},
      num: 0,
      menu: [],
      titleDiv: "",
      menuZh:""
    };
  },
  created() {
    this.menuInfo();
  },
  mounted() {
    this.notificationNum();
  },
  methods: {
    toIndex() {
      this.menu = [];
      sessionStorage.setItem("menu", {});
      this.$emit('activeIndex', 0)
    },
    menuInfo() {
      let data = JSON.parse(sessionStorage.getItem("menu"));
      let info = data instanceof Array ? data[0].treeNames : data.treeNames;
      this.menu = info.split("/").filter((item) => !!item);
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == this.menu[0];
      });
      this.menuZh = obj.extend.extendS3
    },
    activeIndexFun(str) {
      this.$emit("activeIndexFun", str);
    },
    showModel() {
      this.$session("userInfo").get((value) => {
        this.userInfo = { ...{}, ...value };
      });
      this.rightCard = true;
      this.showMask = true;
    },
    openNotification() {
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Notification";
      });
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("jumpRoute");
      this.$router.push({
        name: "Notification",
        params: { notificationStatus: "0" },
      });
    },
    openTracking() {
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Tracking";
      });
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("jumpRoute");
      this.$router.push({
        name: "Tracking",
        params: { notificationStatus: "0" },
      });
    },
    openTask() {
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Task";
      });
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("jumpRoute");
      this.$router.push({
        name: "Task",
        params: { notificationStatus: "0" },
      });
    },
    notificationNum() {
      let that = this;
      that.$ajax
        .cPost(that.source.notification_api.unReadNumber, {})
        .then((res) => {
          if (res.data.result == "true") {
            this.num = res.data.list.unReadNumber;
          } else {
            this.num = 0;
          }
        });
    },
    showMenu() {
      this.menuCard = true;
      this.showMask = true;
    },
    closeMask() {
      this.closeMenu = true;
      this.showMask = false;
      this.menuCard = false;
      this.rightCard = false;
      this.closeMenu = false;
    },
    search() {
      this.$message.info("您输入了：" + this.input5);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";

.kHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  .kHeader-left {
    display: flex;
    align-items: center;
    .menuIcon {
      .menuIcon1 {
        height: 100px;
        .svg-icon {
          width: 95px;
          height: 95px;
          margin: 25px 25px 25px -10px;
        }
        // border: 4px solid $mainColor;
      }
    }
    .titleDiv {
      //margin-top: 30px;
    }

    .dashboardTitle {
      text-align: left;
      margin-left: 20px;
      font-size: 18px;
      .dashboardTitle-line1 {
        color: #9b9b9b;
        line-height: 1.5;
      }
      .dashboardTitle-line2 {
        color: $background2;
        font-style: italic;
        line-height: 1.5;
      }
    }

    .title {
      color: $mainColor;
      font-size: 30px;
      text-align: left;
      font-family: "SEMIBOLD";
      line-height: 30px;
      margin-bottom: 5px;
    }
  }
  .el-breadcrumb__inner a,
  .el-breadcrumb__inner.is-link {
    color: $fontColor1;
    font-family: "montserratLight";
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: $background2;
  }

  .left {
    background-color: rgba($color: #fff, $alpha: 0) !important;
    box-shadow: 0 0 0;
    width: 640px !important;
    .menuIcon2 {
      width: 140px;
      height: 140px;
      background-color: $mainColor;
      z-index: 1000;
      fill: #fff;
      stroke: #fff;
      .svg-icon {
        border: 2px solid #fff;
        width: 40px;
        height: 40px;
        margin: 26px;
        padding: 20px;
        color: #fff;
      }
    }
  }

  .closeMenu {
    transform: all 0.4s;
    animation-name: closeMenuAround;
    animation-duration: 0.4s;
  }

  @keyframes menuAround {
    0% {
      margin-left: -640px;
    }
    100% {
      margin-left: 0;
    }
  }

  @keyframes closeMenuAround {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -640px;
    }
  }

  .kHeader-right {
    .menuIcon {
      display: flex;
      margin-top: 30px;
      .search {
        width: 530px;
        display: flex;
        box-shadow: -3px 2px 6px rgba($color: #000000, $alpha: 0.5);

        input {
          height: 60px;
          width: 60px;
          border: 2px solid $mainColor;
          width: 464px;
          line-height: 60px;
          font-size: 24px;
          padding: 0 10px;
          outline: medium;
        }
        .search-button {
          height: 64px;
          width: 64px;
          background-color: $mainColor;
          border: 0;
          .svg-icon {
            height: 24px;
            width: 24px;
            font-size: 18px;
          }
        }

        .search-button ::after {
          border: 0;
        }
      }
      .icon {
        height: 40px;
        width: 40px;
        border: 2px solid $mainColor;
        margin-left: 10px;
        color: $mainColor;
        line-height: 24px;
        box-shadow: -3px 2px 6px rgba($color: #000000, $alpha: 0.5);
        .svg-icon {
          margin: 9px;
          height: 24px;
          width: 24px;
          fill: $mainColor;
          stroke: $mainColor;
        }
        .el-badge__content.is-fixed {
          right: 22px;
        }
      }
    }
  }

  .headerRight {
    // transform: all 0.4s;
    // animation-name: around;
    // animation-duration: 0.4s;
    height: 100%;
  }

  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2001;
    transform: all 0.4s;
    animation-name: maskAround;
    animation-duration: 0.4s;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  .closeMask {
    transform: all 0.4s;
    animation-name: closeMaskAround;
    animation-duration: 0.4s;
  }

  @keyframes maskAround {
    0% {
      background-color: rgba($color: #000000, $alpha: 0);
    }
    100% {
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  @keyframes closeMaskAround {
    0% {
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
    100% {
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }

  .right {
    width: 700px !important;
  }

  .closeRight {
    transform: all 0.4s;
    animation-name: closeAround;
    animation-duration: 0.4s;
  }

  @keyframes around {
    0% {
      margin-right: -700px;
    }
    100% {
      margin-right: 0;
    }
  }

  @keyframes closeAround {
    0% {
      margin-right: 0;
    }
    100% {
      margin-right: -700px;
    }
  }

  .fourTree {
    .el-breadcrumb__inner.is-link {
      color: #299be4 !important;
    }

    .el-icon-arrow-right::before {
      display: none;
    }
  }
}
</style>
