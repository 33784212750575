<template>
  <div class="right-menu" v-show="userInfo">
    <div class="menu-title">
      <div class="userHead" v-if="!userInfo.avatarUrl">
        <svg-icon icon-class="bigUserHead" class="svg-icon" />
      </div>
      <div class="userHead" v-if="userInfo.avatarUrl">
        <el-image class="image" :src="userInfo.avatarUrl"></el-image>
      </div>
      <div class="userInfo">
        <div class="userName">
          {{ userInfo.firstName }} {{ userInfo.secondName }}
        </div>
        <div class="userEmail">
          <div class="email regula18">{{ userInfo.email || "" }}</div>
          <div class="master regula14">[{{ userInfo.userType || "" }}]</div>
        </div>
        <div class="userNote regula14">
          {{ userInfo.orgName || "" }}
        </div>
      </div>
      <div class="close">
        <div class="menuIcon2" @click="closeMask()">
          <svg-icon icon-class="close" class="svg-icon" />
        </div>
      </div>
    </div>
    <div class="backImageMask"></div>
    <svg-icon icon-class="mineBack" class="backImage"></svg-icon>
    <div class="right-menu-list">
      <div class="menu-line line-into" @click="toSetting()">
        <div class="menu-line-title regula30">{{ $t("lang.account") }}</div>
        <svg-icon class="into-icon" icon-class="into"></svg-icon>
      </div>
      <div class="menu-line">
        <div class="menu-line-title regula30">{{ $t("lang.security") }}</div>
        <div class="Change">
          <div class="password" @click="ChangePassword">
            {{ $t("lang.ChangePassword") }}
          </div>
          <div class="gexian">|</div>
          <div class="MemberID" @click="ChangeMember">
            {{ $t("lang.ChangeMemberID") }}
          </div>
        </div>
      </div>
      <div class="menu-line Language">
        <div class="menu-line-title regula30">{{ $t("lang.Language") }}</div>
        <div class="radio">
          <template>
            <el-radio v-model="radio" label="en_us" @change="changeLanguage">{{
              $t("lang.English")
            }}</el-radio>
            <div class="gexian">|</div>
            <el-radio v-model="radio" label="zh_cn" @change="changeLanguage">{{
              $t("lang.Chinese")
            }}</el-radio>
          </template>
        </div>
      </div>
      <div class="menu-line">
        <div class="menu-line-title regula30">{{ $t("lang.TimeZone") }}</div>
        <div class="time">
          UTC + 8:00 Beijing
          <svg-icon class="down-icon" icon-class="down"></svg-icon>
        </div>
      </div>
      <div class="menu-line line-into">
        <div class="menu-line-title regula30">
          {{ $t("lang.NotificationSetting") }}
        </div>
        <svg-icon class="into-icon" icon-class="into"></svg-icon>
      </div>
      <div class="menu-line line-into" @click="toSetting()">
        <div class="menu-line-title regula30">
          {{ $t("lang.AdvancedSettings") }}
        </div>
        <svg-icon class="into-icon" icon-class="into"></svg-icon>
      </div>
      <div class="logout" @click="showLogOut = true">
        <div class="tabIcon">
          <svg-icon icon-class="Off" class="svg-icon" />
        </div>
        <div class="menu-line-title regula30">
          {{ $t("lang.logout") }}
        </div>
      </div>
    </div>
    <dw-check-box
      :showCheckBox="showLogOut"
      @Cancel="showLogOut = false"
      @Save="LogOut"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.logout')"
    >
      {{ $t("lang.NText176") }}
    </dw-check-box>
  </div>
</template>

<script>
import source from "../../utils/interFConfig";
import dwCheckBox from "../dwCheckBox/dwCheckBox.vue";
import global from "../../utils/global";
export default {
  components: { dwCheckBox },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      menuList: [
        {
          name: "Booking",
          second_level: [
            {
              name: "Booking_level1",
              link: "",
            },
          ],
          link: "",
        },
        {
          name: "Traking",
          second_level: [
            {
              name: "Traking_level1",
              link: "",
            },
          ],
          link: "",
        },
      ],
      radio: this.$i18n.locale,
      showLogOut: false,
    };
  },
  created() {
    this.timeZoneList();
    // this.init();
  },
  methods: {
    // init() {
    //   this.$session("userInfo").get((value) => {
    //     this.userInfo = { ...{}, ...value };
    //     console.log(this.userInfo);
    //   });
    // },
    closeMask() {
      this.$emit("closeMask");
    },
    toSetting() {
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Settings";
      });
      console.log(obj);
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("menuInfo");
      this.$router.push({ path: "/Settings/Account" });
      this.closeMask();
    },
    timeZoneList() {
      let that = this;
      that.$session("token").get((value) => {
        var data = {
          __sid: value,
        };
        that.$ajax
          .cPost(that.source.basApi_api.timeZoneList, data)
          .then((res) => {
            if (res.data.result == "false") {
              // window.location.href="http://dewellgroup.com/"
            } else {
            }
          });
      });
    },
    changeLanguage(e) {
      this.$i18n.locale = e;
      sessionStorage.setItem("lang", e);
    },
    ChangePassword() {
      this.$router.push({ name: "Security", params: { changePassword: true } });
      this.$emit("closeMask");
    },
    ChangeMember() {
      this.$router.push({
        name: "Security",
        params: { changeMemberFlag: true },
      });
      this.$emit("closeMask");
    },
    toAccout() {
      this.$router.push({ path: "/Settings/Account" });
      this.closeMask();
    },
    LogOut() {
      let that = this;
      that.$ajax.cPost(that.source.logout).then((res) => {
        if (res.data.result == "false") {
          this.$notify.error({
            title: res.data.message,
            offset: 100,
            duration: 1000,
          });
        } else {
          localStorage.setItem("loginOut", true);
          sessionStorage.clear();
          // sessionStorage.removeItem("@userInfo");
          // sessionStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("loginInfo");
          localStorage.removeItem("deviceId");
          this.$router.replace('/login')
          // console.log(global.dewellUrl);
          // window.location.href =
          //   global.dewellUrl;

          // window.location.href = global.dewellUrl + (this.$i18n.locale == "en" ? "/en_us" : "/zh_cn")+'/bms.html';
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.right-menu {
  width: 700px;
  background-color: $background;
  font-family: "REGULAR";
  position: absolute;
  top: 0;
  bottom: 0;
  font-family: "montserratregular";
  .menu-title {
    height: 150px;
    background: #132569;
    color: #fff;
    display: flex;

    .userHead {
      height: 90px;
      width: 90px;
      border-radius: 45px;
      background-color: #fff;
      margin: 30px 0 30px 50px;
      color: $mainColor;
      overflow: hidden;
      .svg-icon {
        height: 50px;
        width: 50px;
        margin-top: 15px;
        fill: $mainColor;
        stroke: $mainColor;
      }
      .image {
        height: 90px;
        width: 90px;
      }
    }

    .userInfo {
      margin: 24px 30px;
      text-align: left;
      width: 400px;
      .userName {
        font-size: 24px;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 10px;
      }

      .userEmail {
        display: flex;
        line-height: 26px;
        margin-top: 6px;
        font-family: "montserratLight";
        .email {
          margin-right: 30px;
          color: $background2;
          font-size: 18px;
        }
        .master {
          color: #cccccc;
          font-size: 1px;
        }
      }

      .userNote {
        color: #cccccc;
        font-size: 14px;
        font-family: "montserratLight";
      }
    }

    .close {
      margin-top: 50px;
      cursor: pointer;
      .menuIcon2 {
        width: 49px;
        height: 49px;
        .svg-icon {
          width: 49px;
          height: 49px;
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }

  .right-menu-list {
    position: absolute;
    margin: 10px 48px;
    padding: 0 82px 0 122px;
    width: 400px;
    height: 100%;
    background-color: rgba($color: #b3dcf6, $alpha: 0.95);
    z-index: 9999;
    .menu-line {
      text-align: left;
      border-bottom: 2px solid $mainColor;
      color: $mainColor;
      padding: 10px 1.4px 10px 0;
      .menu-line-title {
        line-height: 70px;
        font-size: 30px;
      }
      .Change {
        display: flex;
        text-align: center;
        width: 100%;
        justify-content: center;
        font-size: 14px;
        font-family: "montserratLight";
        .password {
          cursor: pointer;
        }
        .MemberID {
          cursor: pointer;
        }
      }
      .gexian {
        margin: 0 10px;
        line-height: 21px;
      }

      .radio {
        display: flex;
        justify-content: end;
        font-size: 18px;
        .el-radio {
          margin-right: 0;
          height: 21px;
          line-height: 21px;
          font-size: 18px;
          .el-radio__inner {
            height: 16px;
            width: 16px;
          }
          .el-radio__inner::after {
            height: 8px;
            width: 8px;
          }
          .el-radio__label {
            font-size: 18px;
            padding-left: 10px;
            font-family: "montserratLight";
            line-height: 10px !important;
          }
        }
      }

      .time {
        text-align: right;
        color: #666;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: end;

        .down-icon {
          width: 1.4px;
          height: 10px;
          margin-left: 35px;
        }
      }
    }

    .logout {
      display: flex;
      line-height: 90px;
      color: $mainColor;
      cursor: pointer;
      .svg-icon {
        height: 25px;
        width: 25px;
        margin-right: 20px;
      }
    }

    .line-into {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .into-icon {
        margin: 25px 0;
        height: 25px;
        width: 25px;
      }
    }
  }
  .backImage {
    position: absolute;
    width: 600px;
    height: 464px;
    bottom: 48px;
    left: 48px;
    z-index: 999;
  }
}
</style>
