<template>
  <div class="quotationUserGuide">
    <div id="guideMask"></div>
    <div class="background">
      <div class="header">
        <div class="kHeader-left">
          <div class="menuIcon">
            <div class="menuIcon1">
              <svg-icon icon-class="tab" />
            </div>
          </div>
          <div class="titleDiv">
            <div class="title">
              {{ $t("lang.QText86") }}
            </div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item
                ><span>{{ $t("lang.QText86") }}</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                ><span>{{ $t("lang.getAQuote") }}</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>

        <div class="kHeader-right">
          <div class="menuIcon">
            <div class="icon icon1">
              <svg-icon icon-class="location" class="svg-icon" />
            </div>
            <div class="icon icon2">
              <svg-icon icon-class="todo" class="svg-icon" />
            </div>
            <div class="icon icon3">
              <svg-icon icon-class="remind" class="svg-icon" />
            </div>
            <div class="icon icon4">
              <svg-icon icon-class="userHead" class="svg-icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="kTabs" v-if="tabsList.length > 0">
          <div
            class="kTabs-left-line regula20"
            :key="key1"
            v-for="(item, key1) in tabsList"
          >
            <div class="tab">
              <div class="tabIcon">
                <svg-icon :icon-class="item.menuIcon" class="svg-icon" />
              </div>
              <div class="tabName">
                <span v-if="$i18n.locale == 'en_us'">{{ item.menuName }}</span>
                <span v-if="$i18n.locale == 'zh_cn'">{{
                  item.extend.extendS3
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="quotation">
          <div class="title">{{ $t("lang.newQuote") }}</div>
          <div class="typeList">
            <div
              v-for="(item, index) in quotationTypeList"
              :key="item.dictValue"
            >
              <div
                class="type-icon-main"
                v-show="item.show"
                :style="{ left: 1.25 * index + 'rem' }"
                :ref="'quotationType' + index"
                :class="{ noneType: index > 0 }"
              >
                <svg-icon
                  :icon-class="item.cssClass"
                  class="svg-icon"
                ></svg-icon>
                <div class="type-title">{{ item.dictLabel }}</div>
              </div>
            </div>
            <div
              v-for="(item, index) in quotationTypeList"
              :key="item.dictValue"
              class="type-item"
              v-show="showType2[index]"
            >
              <div class="type-icon-main">
                <svg-icon
                  :icon-class="item.cssClass"
                  class="svg-icon"
                ></svg-icon>
                <div class="type-title">{{ item.dictLabel }}</div>
              </div>
              <transition name="hoverDiv">
                <div class="children" v-show="showType2[index]">
                  <div class="radio">
                    <el-radio
                      v-model="quotationType"
                      :label="item2.dictValue"
                      v-for="item2 in item.children"
                      :key="item2.dictValue"
                      class="radio-item"
                    >
                      <svg-icon :icon-class="item2.dictValue"></svg-icon
                      >{{ item2.dictValue }} </el-radio
                    ><svg-icon icon-class="left" class="left-icon"></svg-icon>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="Quote">
            <el-button type="primary" v-show="false">{{
              $t("lang.QText85")
            }}</el-button>
          </div>

          <div class="or">{{ $t("lang.BText76") }}</div>
          <div class="otherList">
            <div class="type1">
              {{ $t("lang.QText5") }}
              <svg-icon icon-class="into"></svg-icon>
            </div>
            <div class="type1">
              {{ $t("lang.QText6") }}
              <svg-icon icon-class="into"></svg-icon>
            </div>
            <div class="type1">
              {{ $t("lang.QText7") }}
              <svg-icon icon-class="into"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wellcome" v-show="step == '0'">
      <div class="wellcomeiWell">Link Me</div>
      <div class="wellcomeTitle">{{ $t("lang.UserGuide") }}</div>
      <div class="Section">
        <span style="color: #9b9b9b">{{ $t("lang.Section") }} .</span
        ><span style="color: #132569">{{ $t("lang.QText86") }}</span>
      </div>
      <div class="bottom-button">
        <dw-button type="primarySmall" class="dwButton">{{
          $t("lang.Start")
        }}</dw-button>
        <dw-button type="infoSmall" class="dwButton">{{
          $t("lang.QText37")
        }}</dw-button>
      </div>
      <el-checkbox class="dontShow" v-model="checked">{{
        $t("lang.dontShow")
      }}</el-checkbox>
    </div>
    <div class="" v-show="step == '1'">

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      checked: false,
      tabsList: [
        {
          menuName: "Get a quote",
          menuIcon: "quote",
          extend: {
            extendS3: "询价",
          },
        },
        {
          menuName: "My quotation",
          menuIcon: "quotation",
          extend: {
            extendS3: "我的报价单",
          },
        },
        {
          menuName: "Template",
          menuIcon: "template",
          extend: {
            extendS3: "模板",
          },
        },
      ],
      quotationTypeList: [
        {
          dictLabel: "Ocean freight",
          dictValue: "Ocean_freight",
          cssClass: "oceanFreight",
        },
        {
          dictLabel: "Air freight",
          dictValue: "Air_freight",
          cssClass: "airFreight",
        },
        {
          dictLabel: "eCommerce",
          dictValue: "eCommerce",
          cssClass: "eCommerce",
        },
        {
          dictLabel: "End to end solution",
          dictValue: "End_to_end_solution",
          cssClass: "endToEnd",
        },
      ],
      showType2: [false, false, false, false],
      showType: [true, false, false, false],
      step: "1",
    };
  },
};
</script>

<style lang="scss">
@import "../assets/base.scss";
.quotationUserGuide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;

  .background {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 140px;
      .kHeader-left {
        display: flex;
        .menuIcon {
          .menuIcon1 {
            height: 140px;
            .svg-icon {
              width: 95px;
              height: 95px;
              margin: 25px 25px 25px -10px;
            }
            // border: 4px solid $mainColor;
          }
        }
        .titleDiv {
          margin-top: 30px;
        }

        .dashboardTitle {
          text-align: left;
          margin: 34px 0 0 20px;
          font-size: 20px;
          .dashboardTitle-line1 {
            color: #9b9b9b;
            margin-bottom: 10px;
          }
          .dashboardTitle-line2 {
            color: $background2;
            font-style: italic;
          }
        }

        .title {
          color: $mainColor;
          font-size: 60px;
          text-align: left;
          font-family: "SEMIBOLD";
          line-height: 60px;
          margin-bottom: 5px;
        }
      }
      .kHeader-right {
        .menuIcon {
          display: flex;
          margin-top: 30px;
          .search {
            width: 530px;
            display: flex;
            box-shadow: -3px 2px 6px rgba($color: #000000, $alpha: 0.5);

            input {
              height: 60px;
              width: 60px;
              border: 2px solid $mainColor;
              width: 464px;
              line-height: 60px;
              font-size: 24px;
              padding: 0 10px;
              outline: medium;
            }
            .search-button {
              height: 64px;
              width: 64px;
              background-color: $mainColor;
              border: 0;
              .svg-icon {
                height: 24px;
                width: 24px;
                font-size: 18px;
              }
            }

            .search-button ::after {
              border: 0;
            }
          }
          .icon {
            height: 60px;
            width: 60px;
            border: 2px solid $mainColor;
            margin-left: 10px;
            color: $mainColor;
            line-height: 24px;
            box-shadow: -3px 2px 6px rgba($color: #000000, $alpha: 0.5);
            .svg-icon {
              margin: 18px;
              height: 24px;
              width: 24px;
              fill: $mainColor;
              stroke: $mainColor;
            }
            .el-badge__content.is-fixed {
              right: 22px;
            }
          }
        }
      }
    }
    .main{
      display: flex;
    }
    .kTabs {
      text-align: left;
      margin: 0 30px 0 0;
      width: 300px;
      height: 100%;
      .kTabs-left-line {
        text-align: left;
        height: 64px;

        .tab {
          display: flex;
        }
        a {
          text-decoration: none;
        }
        .offTabIcon {
          height: 60px;
          width: 64px;
          display: flex;
          align-items: center;
          .svg-icon {
            height: 20px !important;
            width: 20px !important;
            padding-left: 21px;
            fill: $mainColor;
            stroke: $mainColor;
          }
        }
        .tabIcon {
          height: 60px;
          width: 64px;
          display: flex;
          align-items: center;
          .svg-icon {
            height: 22px !important;
            width: 22px !important;
            padding-left: 21px;
            fill: $mainColor;
            stroke: $mainColor;
          }
        }
        .tabName {
          color: $mainColor;
          letter-spacing: 0px;
          line-height: 64px;
        }
      }
      .choose {
        background-color: $background4;
        .svg-icon {
          fill: $background2 !important;
          stroke: $background2 !important;
        }
        .tabName {
          color: $background2;
        }
      }

      .kTabs-left-line:hover {
        background-color: $background4;
      }
      .signOut {
        cursor: pointer;
      }
    }
    .quotation {
      width: 900px;
      height: auto;
      color: $mainColor;
      .Quote {
        display: flex;
        text-align: center;
        justify-content: end;
        margin-right: 100px;
      }

      .title {
        font-size: 30px;
        color: $mainColor;
        line-height: 64px;
        text-align: left;
      }
      .typeList {
        // display: flex;
        // justify-content: space-between;
        margin-bottom: 30px;
        // flex-wrap: wrap;
        position: relative;
        height: 200px;
        width: 900px;

        .type-icon-main {
          height: 200px;
          width: 200px;
          border: 2px solid $mainColor;
          position: absolute;
          margin-bottom: 10px;
          margin-right: 21px;
          .hoverType {
            background-color: $background;
            z-index: 99 !important;
          }

          .svg-icon {
            width: 100px;
            height: 100px;
            padding-top: 28px;
            color: $mainColor;
          }
          .type-title {
            font-size: 18px;
            color: $mainColor;
            margin-top: 20px;
            line-height: 18px;
          }
        }
        .noneType {
          border: 2px solid $fontColor1;
          .type-title {
            color: $fontColor1 !important;
          }
          .svg-icon {
            fill: $fontColor1;
            stroke: $fontColor1;
          }
        }
      }
      .type-item {
      }

      .children {
        position: absolute;
        left: 220px;
        right: 0;
        .radio {
          display: flex;
          align-items: center;
          height: 150px;
          .radio-item {
            margin: 0 20px;
          }
          .svg-icon {
            height: 100px;
            width: 100px;
            margin-right: 10px;
            margin-left: 20px;
          }
          .left-icon {
            top: 30px;
            height: 50px;
            width: 50px;
            padding: 7px;
            color: #c0c0c0;
            border-radius: 32px;
            margin-left: 20px;
            position: absolute;
            right: 0;
            fill: #c0c0c0;
            stroke: #c0c0c0;
          }
          .left-icon:hover {
            background-color: #f9f9f9;
          }
        }
      }

      .or {
        width: 100%;
        border-bottom: 2px solid $mainColor;
        line-height: 60px;
        font-size: 30px;
        text-align: left;
        padding-left: 20px;
      }

      .otherList {
        text-align: left;
        line-height: 80px;
        font-size: 20px;
        width: 420px;
        .type1 {
          padding: 0 20px;
          border-bottom: 1px solid #e6e6e6;
          cursor: pointer;

          .svg-icon {
            margin-left: 40px;
          }
        }
      }

      .fillInDialog {
        padding-top: 20px;
        .el-dialog__header {
          display: none;
        }
        .el-dialog__body {
          padding: 0;
          .fillInHeader {
            height: 100px;
            display: flex;
            justify-content: space-between;
          }
          .headerTitle {
            font-size: 30px;
            color: $mainColor;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            margin-left: 20px;
            line-height: 100px;
          }
          .close {
            width: 30px;
            height: 30px;
            margin-top: 40px;
            margin-right: 30px;

            cursor: pointer;
            .close-icon {
              width: 30px;
              height: 30px;
              fill: $mainColor;
              stroke: $mainColor;
            }
          }
        }
      }
    }
  }

  .wellcome {
    position: absolute;
    top: 185px;
    width: 1038px;
    height: 500px;
    left: 50%;
    margin-left: -519px;
    background-color: #fff;
    .wellcomeiWell {
      color: $mainColor;
      font-size: 48px;
      font-family: "montserratBalck";
      margin-top: 100px;
    }
    .wellcomeTitle {
      color: $background2;
      font-size: 96px;
      font-family: "montserratBalck";
    }
    .Section {
      margin-top: 20px;
      font-size: 28px;
    }
    .bottom-button {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .dwButton {
        width: 180px;
        .el-button {
          width: 160px;
        }
      }
    }
    .dontShow {
      color: #cdcdcd;
      position: absolute;
      left: 530px;

      .el-checkbox__label {
        line-height: 38px;
        font-size: 16px;
        font-family: "montserratLight";
      }
    }
  }
}
</style>
