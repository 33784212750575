//获取基础数据

import { cGet, cPost } from './utils/ajax'
import source from './utils/interFConfig'
import $session from './utils/storageManager/storageManager'


const findContainerTypeList = function () {
    cGet(source.quotation_api.findContainerTypeList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('ContainerTypeList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findCargoTypeList = function () {
    cGet(source.quotation_api.findCargoTypeList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('CargoTypeList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}
const findWeightUnitList = function () {
    cGet(source.quotation_api.findWeightUnitList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('WeightUnitList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}
const findVolumeUnitList = function () {
    cGet(source.quotation_api.findVolumeUnitList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('VolumeUnitList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}
const findCargoUnitList = function () {
    cGet(source.quotation_api.findCargoUnitList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('CargoUnitList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}
const findWarehouseList = function () {
    cGet(source.quotation_api.findWarehouseList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('WarehouseList').set(res.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findCurrentyList = function () {
    cGet(source.quotation_api.findCurrentyList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('CurrentyList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findIncotermsData = function () {
    cGet(source.quotation_api.findIncotermsData, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('incotermsData').set(res.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const getCountryList = function () {
    cPost(source.address_api.getCountryList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('countryList').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findQuotationType = function () {
    cPost(source.quotation_api.findQuotationType, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('quotationType').set(res.data.mainTypeData)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findCommodityTypeList = function () {
    cPost(source.quotation_api.findCommodityTypeList, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('CommodityType').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const bookingStatus = function () {
    cPost(source.booking_api.bookingStatus, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('bookingStatus').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findPackUnit = function () {
    cPost(source.quotation_api.findPackUnit, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('packUnit').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findVolumeUnitListForTotal = function () {
    cPost(source.quotation_api.findVolumeUnitListForTotal, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('VolumeUnitListForTotal').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}

const findCargoUnitListForTotal = function () {
    cPost(source.quotation_api.findCargoUnitListForTotal, {})
        .then((res) => {
            if ((res.resultCode = "success")) {
                $session.session('CargoUnitListForTotal').set(res.data.data)
            } else {
                this.$notify.error({
                    title: res.message,
                    offset: 100,
                    duration: 3000,
                });
            }
        });
}


findCargoTypeList();
findCargoUnitList();
findVolumeUnitList();
findWarehouseList();
findWeightUnitList();
findContainerTypeList();
findCurrentyList();
findIncotermsData();
getCountryList();
findQuotationType();
findCommodityTypeList();
bookingStatus();
findPackUnit();
findVolumeUnitListForTotal();
findCargoUnitListForTotal();




