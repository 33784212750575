<template>
  <div class="kSecondHeader">
    <el-menu
      :default-active="activeIndexs"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item
        v-for="(item, index) in data"
        :key="index"
        :index="index + ''"
        v-show="item.isShow == '1' || !item.isShow"
        ><span v-if="$i18n.locale == 'en_us'">{{ item.menuName }}</span>
        <span v-if="$i18n.locale == 'zh_cn'">{{
          item.extend.extendS3
        }}</span></el-menu-item
      >
    </el-menu>
    <div class="search" @click="Search" v-show="showSearch">
      <svg-icon icon-class="search"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "secondHeader",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    clickchange: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: [Number, String],
      default: () => "0",
    },
  },
  computed: {
    activeIndexs: {
      get() {
        return this.activeIndex;
      },
    },
  },
  data() {
    return {};
  },
  activated() {},
  methods: {
    handleSelect(tab, event) {
      if (!this.clickchange) {
        this.$emit("handleSelect", tab);
        return;
      }
      if (tab == 4 || tab == 5 || tab == 6) {
        sessionStorage.setItem("menu", JSON.stringify(this.data[tab]));
      } else {
        sessionStorage.setItem(
          "menu",
          JSON.stringify(this.data[tab].childList[0])
        );
      }
      sessionStorage.setItem("kSecondHeader", tab);
      this.$router.push(this.data[tab].menuUrl);
      this.$emit("menuInfo");
    },
    Search() {
      this.$emit("Search");
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.kSecondHeader {
  margin-bottom: 30px;
  position: relative;
  .el-menu {
    border-bottom: 4px solid $mainColor !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      border-bottom: 0 !important;
      margin-bottom: -1px !important;
      font-size: 20px !important;
    }
    .is-active {
      border-bottom: 0 !important;
      background-color: $mainColor !important;
      color: $background2 !important;
      margin-bottom: -1px !important;
    }
    .el-menu-item:not(.is-disabled):hover {
      background-color: $background;
      color: #fff;
    }
  }
  .search {
    position: absolute;
    right: 0;
    bottom: 10px;
    .svg-icon {
      color: $mainColor;
      fill: $mainColor;
      stroke: $mainColor;
    }
  }
}
</style>