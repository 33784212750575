<template>
  <div id="app" v-loading="showLoading">
    <router-view v-if="$route.name == 'login'" @setLoginFlag="setLoginFlag"></router-view>

    <div v-if="$route.name !== 'login' && isLogin" class="appMenu">
      <!--菜单-->
      <k-menu ref="kMenu" :menuList="menuList" @menuInfo="menuInfo" @closeMask="closeMask" @setActiveMenuIndex="val => activeMenuIndex = val"
              :activeMenuIndex="activeMenuIndex"></k-menu>
    </div >
    <div class="appContent" v-if="$route.name !== 'login' && isLogin" @click="$refs.kMenu.close()">
      <div style="height: 100%; overflow-y: auto">
        <el-container>
<!--          <div id="top" @click="showTop($store.state.api.showTop)">-->
<!--            <svg-icon v-show="$store.state.api.showTop" icon-class="up"></svg-icon>-->
<!--            <svg-icon-->
<!--                v-show="!$store.state.api.showTop"-->
<!--                icon-class="down"-->
<!--            ></svg-icon>-->
<!--          </div>-->
          <el-header v-show="$store.state.api.showTop">
            <k-header
                ref="Kheader"
                :class="{ hideTop: hideTop, showTop: $store.state.api.showTop }"
                :menuList="menuList"
                v-if="menuList && menuList.length"
                @activeIndexFun="activeIndexFun"
                @jumpRoute="jumpRoute"
                @activeIndex="val => activeMenuIndex = val"
            ></k-header>
          </el-header>
          <div id="main">
            <k-second-header
                v-if="secondList && secondList.length && showHeader2"
                :data="secondList"
                :activeIndex="activeIndex"
                @menuInfo="menuInfo"
            ></k-second-header>
            <el-container>
              <el-aside width="300px">
                <k-tabs
                    @menuInfo="menuInfo"
                    v-if="tabList && (showHeader2 || showHeader1)"
                ></k-tabs>
              </el-aside>
              <el-main>
                <router-view
                    @drawerClick="drawerClick"
                    @historyClick="historyClick"
                    @Fun="Fun"
                    @jumpRoute="jumpRoute"
                />
                <div class="chatIcon" @click="openDrawer">
                  <!-- <el-badge :value="5" class="item" :max="99"> -->
                  <el-badge class="item" :max="99">
                    <svg-icon icon-class="chat" class="svg-icon" />
                  </el-badge>
                </div>
              </el-main>
            </el-container>
          </div>
        </el-container>
        <guide v-show="false"></guide>
        <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
        <iframe-box
            ref="iframeBoxApp"
            :orderNum="orderNum"
            :orderType="orderType"
        ></iframe-box>
        <histoty-dialog
            :historyVisible.sync="historyVisible"
            :orderNum="orderNum"
            :orderType="orderType"
        ></histoty-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import kHeader from "@/components/kHeader/kHeader.vue";
import kSecondHeader from "@/components/kHeader/kSecondHeader.vue";
import kTabs from "@/components/kTabs/kTabs.vue";
import dwChat from "@/components/dwChat/index.vue";
import global from "./utils/global";
import history from "./components/dwChat/history.vue";
import histotyDialog from "./components/dwChat/histotyDialog.vue";
import iframeBox from "./components/iframe/iframeBox.vue";
import guide from "./userGuide/index.vue";
import KMenu from './components/kHeader/kMenu'
export default {
  name: "App",
  components: {
    KMenu,
    kHeader,
    kSecondHeader,
    kTabs,
    dwChat,
    history,
    iframeBox,
    histotyDialog,
    guide,
  },
  data() {
    return {
      activeMenuIndex: null,
      isLogin: false,
      isIframe: false,
      shipmentData: {},
      dialogTitle: "Chatting history",
      activeIndex: "0",
      direction: "rtl",
      menuList: [],
      homeList: [],
      hideTop: false,
      drawer: false,
      historyVisible: false,
      bid: "",
      ChatToSendMsg: {},
      toSendMsg: {},
      secondList: [],
      orderNum: "",
      orderType: "",
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          header: {
            title: "Get Started",
          },
          content: `Discover <strong>Vue Tour</strong>!`,
        },
        {
          target: ".v-step-1",
          content: "An awesome plugin made with Vue.js!",
        },
        {
          target: '[data-v-step="2"]',
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
    };
  },
  computed: {
    showHeader2() {
      return this.$route.path.indexOf("Settings") > 0;
    },
    showHeader1() {
      return this.$route.path.indexOf("Quotation") > 0;
    },
    showLoading() {
      return this.$store.state.api.loading;
    },
    tabList() {
      return JSON.stringify(sessionStorage.getItem("tabList")).length;
    },
  },
  watch: {
    // '$route' (to, from) {
    //   if(!from.name) {
    //     this.activeIndex = '0';
    //   } else {
    //     if(!sessionStorage.getItem("kSecondHeader")) sessionStorage.setItem("kSecondHeader", '0')
    //     this.activeIndex = sessionStorage.getItem("kSecondHeader")
    //   }
    // }
  },
  updated() {},
  created() {
    if (document.getElementsByClassName("first-loading-wrp")[0]) {
      document.getElementsByClassName("first-loading-wrp")[0].remove();
    }
    if (window.location.href.indexOf("token") > 0) {
      this.parseQuery(window.location.href);
    }

    window.removeEventListener("setItem", () => {
      this.secondList = JSON.parse(sessionStorage.getItem("secondList"));
    });
    window.addEventListener("setItem", () => {
      this.secondList = JSON.parse(sessionStorage.getItem("secondList"));
    });
    if (this.menuList.length == 0) {
      this.secondList = JSON.parse(sessionStorage.getItem("secondList"));
    }
    // sessionStorage.setItem("lang", "en_us");
  },
  mounted() {
    this.getUserInfo();
  },
  unmounted() {},
  methods: {
    closeMask() {
      this.$refs["Kheader"].closeMask();
    },
    setLoginFlag() {
      this.isLogin = false
      window.location.reload()
    },
    jumpRoute() {
      this.$refs["Kheader"].menuInfo();
    },
    activeIndexFun(str) {
      this.activeIndex = str;
    },
    menuInfo() {
      this.$refs["Kheader"].menuInfo();
    },
    Fun() {
      this.$refs.Kheader.notificationNum();
    },
    drawerClick(obj) {
      this.openDrawer(obj);
    },
    historyClick(obj) {
      this.orderNum = obj.num || "";
      this.orderType = obj.type || "";
      this.historyVisible = true;
    },
    showTop(flag) {
      if (flag) {
        this.hideTop = true;
        setTimeout(() => {
          this.$store.state.api.showTop = false;
          this.hideTop = false;
        }, 150);
        return;
      }
      this.$store.state.api.showTop = true;
    },
    handleClose() {
      this.drawer = false;
    },
    openDrawer(obj) {
      this.orderNum = obj.num;
      this.orderType = obj.type || "";
      this.$refs["iframeBoxApp"].openCheckBox();
      // return;
      // this.chatDrawerOpen(obj);
    },
    // chatDrawerOpen(obj) {
    //   let data = {
    //     bid: this.bid,
    //     btype: '0',
    //   }
    //   if(obj.bid) {
    //     data.bid = obj.bid;
    //     data.btype = obj.btype;
    //   };
    //   if((obj.btype == '10' || obj.btype == '20' || obj.btype == '30' || obj.btype == '40') && !obj.bid) {
    //     return;
    //   };
    //   this.$ajax.cPost(this.source.account_api.toSendMsg, data).then((res) => {
    //       if (res.data.result == "true") {
    //         this.toSendMsg = res.data.msgMain;
    //         this.drawer = true;
    //       } else {
    //           this.$notify({
    //               title: res.data.message,
    //               type: "error",
    //               offset: 100,
    //           });
    //       }
    //   });
    // },
    parseQuery(url) {
      let o = {};
      let queryString1 = url.split("?")[1];
      let queryString = queryString1.split("#")[0];
      if (queryString) {
        queryString.split("&").forEach((item) => {
          let [key, val] = item.split("=");
          val = val ? decodeURI(val) : true;
          //          转码         无值赋值true
          if (o.hasOwnProperty(key)) {
            //   已有属性转为数组
            o[key] = [].concat(o[key], val);
          } else {
            o[key] = val;
          }
        });
      }
      console.log(o);
      this.$session("token").set(o.token || localStorage.getItem('token'));
      if (o.lan) {
        this.$i18n.locale = o.lan;
        sessionStorage.setItem("lang", o.lan);
      } else {
        sessionStorage.setItem("lang", "en_us");
      }
      // window.location.href = global.dewellUrl + "/myaccount/";  //删掉
    },
    getUserInfo() {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax.cPost(this.source.account_api.getUserInfo).then((res) => {
        if (res.data.result == "false") {
          this.$router.replace('/login')
          this.$store.commit("api/SET_LOADING", false);
          // window.location.href = global.dewellUrl;
          this.isLogin = false
        } else {
          this.$session("userInfo").set(res.data.user);
          this.bid = res.data.user.id;
          this.isLogin = true
          this.getMenuList();
        }
      });
    },
    //遍历菜单
    recursive(arr) {
      const testFun = function (i, arrData) {
        if (arrData[i] == undefined) {
          return;
        } else {
          if (!("childList" in arrData[i])) {
            arrData[i].childList = [];
          } else {
            testFun(0, arrData[i].childList);
          }
          i++;
          testFun(i, arrData);
        }
      };
      testFun(0, arr);
    },
    getMenuList() {
      this.$session("token").get((value) => {
        var data = {
          __sid: value,
        };
        this.$ajax.cPost(this.source.account_api.menuTree, data).then((res) => {
          let arr = [...[], ...res.data.data];
          this.recursive(arr);
          this.$session("menuList").set(arr);
          this.menuList = arr;
          this.$store.commit("api/SET_LOADING", false);
          if (!sessionStorage.getItem("menu")) {
            let obj = JSON.parse(sessionStorage.getItem("@menuList")).find(
              (res) => {
                return res.menuName == "Dashboard";
              }
            );
            sessionStorage.setItem("menu", JSON.stringify(obj));
          }
          sessionStorage.setItem("tabList", JSON.stringify([]));
          // this.$router.push("/");
        });
      });
    },
    chatClick(obj) {
      this.ChatToSendMsg = obj;
      this.historyVisible = true;
    },
  },
};
</script>

<style lang="scss">
@import "assets/base.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: "montserratregular";
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  .appMenu {
    width: 60px;
    height: 100%;
    background: $mainColor;
  }
  .appContent {
    width: calc(100% - 60px);
    height: 100%;
  }
}
html {
  width: 100%;
  height: 100%
}
body {
  margin: 0;
  width: 100%;
  height: 100%
}
.page {
  margin: 0;
  padding: 0;
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  transform: all 0.4s;
  animation-name: maskAround;
  animation-duration: 0.4s;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
#main {
  margin: 0 30px;
  font-size: 20px;
  overflow-y: auto;
}

#top {
  height: 30px;
  width: 80px;
  background-color: $background5;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -40px;
  box-shadow: -3px 2px 6px rgba($color: #000000, $alpha: 0.3);
  z-index: 998;
  .svg-icon {
    width: 25px;
    height: 10px;
    margin: 10px 0;
    fill: #c5c8d1;
    stroke: #c5c8d1;
  }
}

.el-aside {
  width: auto !important;
}

.el-main {
  padding: 0 !important;
  min-width: 900px;
  font-family: "montserratregular";
  overflow-x: hidden !important;
}

.el-header {
  padding: 0 30px !important;
  height: 140px;
  height: auto !important;
  animation-name: headerShow;
  animation-duration: 0.2s;
  transform: all 0.2s;
}

.hideTop {
  transform: all 0.2s;
  animation-name: headerHide;
  animation-duration: 0.2s;
}

@keyframes headerHide {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -140px;
  }
}

@keyframes headerShow {
  0% {
    margin-top: -140px;
  }
  100% {
    margin-top: 0;
  }
}

// .content {
//   display: flex;
// }

.chatIcon {
  height: 70px;
  width: 70px;
  position: fixed;
  right: 0;
  background-color: $mainColor;
  bottom: 140px;
  box-shadow: -3px 2px 6px rgba($color: #000000, $alpha: 0.5);
  z-index: 2000;
  // border-radius: 3px;
  cursor: pointer;
  transition: 0.5s;
  .svg-icon {
    margin-top: 20px;
    height: 35px;
    width: 35px;
    fill: #fff;
    stroke: #fff;
  }
}
.chatIcon:hover {
  background-color: $background2;
}

.drawer-title {
  // background-color: $mainColor;
  // height: 150px;
  // font-size: 30px;
  // display: flex;
  // justify-content: space-between;
  // color: #fff;

  // .left {
  //   line-height: 150px;
  //   padding-left: 50px;
  //   .svg-icon {
  //     margin-right: 20px;
  //     color: #fff;
  //     height: 36px;
  //     width: 36px;
  //   }
  // }

  // .close {
  //   width: 50px;
  //   height: 50px;
  //   margin: 50px;
  //   cursor: pointer;
  //   .close-icon {
  //     width: 50px;
  //     height: 50px;
  //     color: #fff;
  //   }
  // }
}
.chatMain {
  position: absolute;
  bottom: 0;
  top: 150px;
  left: 0;
  right: 0;
  background-color: $background1;
}
.chatViewDialog {
  .el-dialog {
    height: 100vh;
    min-height: 750px;
    margin: 0 auto 0px;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    height: 100%;
    padding: 0;
  }
  .el-dialog__close {
    display: none;
  }
  .dialog-header {
    height: 105px;
    padding: 20px;
    background-color: $mainColor;
    display: flex;
    .dw-logo {
      width: 70px;
      height: 70px;
      padding: 15px;
      flex: 1;
      .svg-icon {
        width: 70px;
        height: 70px;
        fill: #fff;
        stroke: #fff;
      }
    }
    .close {
      width: 50px;
      height: 50px;
      margin-top: 30px;
      margin-left: 100px;
      flex: 1;
      .close-icon {
        width: 50px;
        height: 50px;
        color: #fff;
      }
    }
    .dialog-title {
      color: #fff;
      margin-left: 90px;
      text-align: left;
      flex: 10;
      .row1 {
        margin-top: 5px;
      }
      .row2 {
        margin-top: 14px;
        color: $fontColor1;
      }
    }
  }
}
</style>

