<template>
  <div>
    <el-drawer
      title=""
      custom-class="BookingDrawer"
      :visible.sync="isDrawer"
      :modal="false"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="false"
      :destroy-on-close="false"
      :modal-append-to-body="true"
      @open="changeDrawer"
      @closed="changeDrawer"
      direction="rtl"
    >
      <div class="iframeBox" v-if="isIframe">
        <div class="drawer-title">
          <div class="left">
            <span>
              <svg-icon icon-class="chat" class="svg-icon"></svg-icon>
              <span style="vertical-align: middle;">{{$t('lang.NText174')}}</span>
            </span>
          </div>
          <div class="close" @click="openCheckBox()">
            <svg-icon class="close-icon" icon-class="close"></svg-icon>
          </div>
        </div>

        <iframe
          frameborder="0"
          border="0"
          class="iframeClass"
          :src="srcIndexUrl"
        ></iframe>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
export default {
  components: {},
  props: {
    orderNum: {
      type: [Number, String],
      default: "",
    },
    orderType: {
      type: [Number, String],
      default: () => "",
    },
  },
  computed: {

  },
  watch: {
      isDrawer: {
          handler:function (val) {
              if(val) this.srcIndex();
          },
          deep:true
      }
  },
  data() {
    return {
      isIframe: false,
      isDrawer: false,
      srcIndexUrl: '',
    };
  },
  created() {},
  methods: {
    srcIndex() {
		// todo : 接 udesk
      const im_user_key = "dfe25baa1b30a80a4054f22c179d882e";
      // let src = `https://1996571.s4.udesk.cn/im_client/?web_plugin_id=30982&language=${sessionStorage.lang.replace('_', '-')}&src_url=recordType-${this.orderType}_recordNo-${this.orderNum}`;
	  let src = "https://1996571.s4.udesk.cn/im_client/"
      let currentTime = Date.now();
      let signStr =
        "nonce=" +
        currentTime +
        "&timestamp=" +
        currentTime +
        "&web_token=" +
        JSON.parse(sessionStorage.getItem("@userInfo"))["id"] +
        "&" +
        "dfe25baa1b30a80a4054f22c179d882e";
      let obj = {
        nonc: "nonce=" + currentTime,
        signature:
          "signature=" + CryptoJS.SHA256(signStr).toString().toUpperCase(),
        // signature: "signature=b590df72c1750eeceeac845af72625f64617a63351f0eb935564d996fbd88112",
        // signature: "signature=1" ,
        timestamp: "timestamp=" + currentTime,
        web_token:
          "web_token=" + JSON.parse(sessionStorage.getItem("@userInfo"))["id"],
        encryption_algorithm: "encryption_algorithm=SHA256",
        // c_cn_title: "c_cn_title=" + this.orderNum, // 单号
        c_name:
          "c_name=" +
          JSON.parse(sessionStorage.getItem("@userInfo"))["userName"],
        c_org:
          "c_org=" +
          JSON.parse(sessionStorage.getItem("@userInfo"))["userType"],
        customer_token:
          "customer_token=" +
          JSON.parse(sessionStorage.getItem("@userInfo"))["id"],
      };
      if (JSON.parse(sessionStorage.getItem("@userInfo"))["email"]) {
        obj["c_email"] =
          "c_email=" + JSON.parse(sessionStorage.getItem("@userInfo"))["email"];
      }
      if (JSON.parse(sessionStorage.getItem("@userInfo"))["mobile"]) {
        obj["c_phone"] =
          "c_phone=" +
          JSON.parse(sessionStorage.getItem("@userInfo"))["mobile"];
      }
      let url = "";
      for (let k in obj) {
        url += "&" + obj[k];
      }
      this.srcIndexUrl =  src;
    },
    openCheckBox() {
      this.isDrawer = !this.isDrawer;
    },
    changeDrawer() {
      this.isIframe = !this.isIframe;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.BookingDrawer {
  width: 29% !important;
  height: 85% !important;
  top: auto !important;
  bottom: 0 !important;
}
.iframeBox {
  width: 100%;
  height: 100%;
  outline: 0;
  position: relative;
  .drawer-title {
    width: 100%;
    height: 62PX;
    background-color: $mainColor;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    .left {
      margin: auto 0;
      box-sizing: border-box;
      font-size: 20px;
      padding-left: 15px;
      .svg-icon {
        margin-right: 10px;
        color: #fff;
        fill: #fff;
        stroke: #fff;
        height: 20px;
        width: 20px;
      }
    }
    .close {
      width: 20Px;
      margin: auto 0;
      cursor: pointer;
      box-sizing: border-box;
      padding-right: 30Px;
      .close-icon {
        width: 20Px;
        height: 20Px;
        fill: #fff;
        stroke: #fff;
        vertical-align: baseline;
      }
    }
  }
  .wall {
    width: 100%;
    height: 4.5%;
  }
  .wall1 {
    width: 100%;
    height: 62PX;
  }
  .iframeClass {
    width: 100%;
    height: 99.5%;
  }
}
</style>
