/**左侧tab组件 */
<template>
  <div class="kTabs" v-if="tabsList.length > 0">
    <div
      class="kTabs-left-line regula20"
      :class="{ choose: item.menuName == menu.menuName }"
      :key="key1"
      v-for="(item, key1) in tabsList"
      @click="clickMenu(item)"
      v-show="item.isShow == '1'"
    >
      <router-link :to="item.menuUrl">
        <div class="tab">
          <div class="tabIcon">
            <svg-icon :icon-class="item.menuIcon" class="svg-icon" />
          </div>
          <div class="tabName">
            <span v-if="$i18n.locale == 'en_us'">{{ item.menuName }}</span>
            <span v-if="$i18n.locale == 'zh_cn'">{{
              item.extend.extendS3
            }}</span>
          </div>
        </div>
      </router-link>
    </div>
    <div
      class="signOut kTabs-left-line"
      v-show="tabsList[0].menuName == 'Home'"
      @click="showLogOut = true"
    >
      <div class="tab">
        <div class="offTabIcon">
          <svg-icon icon-class="Off" class="svg-icon" />
        </div>
        <div class="tabName">{{ $t("lang.NText325") }}</div>
      </div>
    </div>
    <dw-check-box
      :showCheckBox="showLogOut"
      @Cancel="showLogOut = false"
      @Save="LogOut"
      :primaryButton="$t('lang.logout')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.NText176") }}
    </dw-check-box>
  </div>
</template>

<script>
import global from "../../utils/global";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
export default {
  name: "kTabs",
  props: {},
  components: {
    dwCheckBox,
  },
  data() {
    return {
      menu: {
        name: "",
      },
      showLogOut: false,
      tabsList: [],
    };
  },
  props: {
    homeList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    tabsList: function (newVal) {
      if (JSON.parse(sessionStorage.getItem("menu")) instanceof Array) {
        this.menu = JSON.parse(sessionStorage.getItem("menu"))[0];
      } else {
        this.menu = JSON.parse(sessionStorage.getItem("menu"))
          ? JSON.parse(sessionStorage.getItem("menu"))
          : "";
      }
    },
  },
  methods: {
    clickMenu(tab, event) {
      sessionStorage.setItem("menu", JSON.stringify(tab));
      this.resetSetItem("menu", JSON.stringify(tab));
      this.menu = tab;
      this.$emit("handleSelect", tab);
      this.$emit("menuInfo");
    },
    clickLogOut() {},
    LogOut() {
      let that = this;
      that.$ajax.cPost(that.source.logout).then((res) => {
        if (res.data.result == "false") {
          this.$notify.error({
            title: res.data.message,
            offset: 100,
            duration: 3000,
          });
        } else {
          localStorage.setItem("loginOut", true);
          sessionStorage.clear();
          // sessionStorage.removeItem("@userInfo");
          // sessionStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("loginInfo");
          localStorage.removeItem("deviceId");
          this.$router.replace('/login')
        }
      });
    },
  },
  created() {
    window.removeEventListener("setItem", () => {
      this.tabsList = JSON.parse(sessionStorage.getItem("tabList"));
    });
    window.addEventListener("setItem", () => {
      this.tabsList = JSON.parse(sessionStorage.getItem("tabList"));
    });
    if (this.tabsList.length == 0 || !this.tabsList) {
      if (
        JSON.parse(sessionStorage.getItem("tabList")) &&
        JSON.parse(sessionStorage.getItem("tabList")).length
      ) {
        this.tabsList = JSON.parse(sessionStorage.getItem("tabList"));
      }
      // else {
      //   this.tabsList = this.homeList;
      // }
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.kTabs {
  text-align: left;
  margin: 0 30px 0 0;
  width: 300px;
  .kTabs-left-line {
    text-align: left;
    height: 64px;

    .tab {
      display: flex;
    }
    a {
      text-decoration: none;
    }
    .offTabIcon{
      height: 60px;
      width: 64px;
      display: flex;
      align-items: center;
      .svg-icon {
        height: 20px !important;
        width: 20px !important;
        padding-left: 21px;
        fill: $mainColor;
        stroke: $mainColor;
      }
    }
    .tabIcon {
      height: 60px;
      width: 64px;
      display: flex;
      align-items: center;
      .svg-icon {
        height: 22px !important;
        width: 22px !important;
        padding-left: 21px;
        fill: $mainColor;
        stroke: $mainColor;
      }
    }
    .tabName {
      color: $mainColor;
      letter-spacing: 0px;
      line-height: 64px;
    }
  }
  .choose {
    background-color: $background4;
    .svg-icon {
      fill: $background2 !important;
      stroke: $background2 !important;
    }
    .tabName {
      color: $background2;
    }
  }

  .kTabs-left-line:hover {
    background-color: $background4;
  }
  .signOut {
    cursor: pointer;
  }
}
</style>
