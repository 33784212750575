import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import router from './router'
import './utils/storageManager/index'

// import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
import './assets/fonts/stylesheet.css'
import './assets/base.scss'
import filters from './utils/filters'
Vue.use(filters)

import VueI18n from 'vue-i18n'
/**
 * px2rem自适应  如果不想转为rem,'px'写成'PX',注意：已经修改源码（flexible.js）具体看源文件
 */
import './utils/flexible'
import './utils/remConfig'

import ajax from './utils/ajax'
Vue.prototype.$ajax = ajax;

import http from './utils/http'
Vue.prototype.$http = http;

import source from './utils/interFConfig'
Vue.prototype.source = source;

import websoketGlobal from './utils/websoket'
Vue.prototype.$websoketGlobal = websoketGlobal

import './baseData'
// import Udesk from './utils/udesk';
// Udesk.init()
// Vue.prototype.$Udesk = Udesk;
/**
 * 语言切换
 */


import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zhCn =  require('./assets/lang/zh_cn')
const enUs =  require('./assets/lang/en_us')
Vue.use(VueI18n)
const messages = {
  en_us: {
    ...enUs,
    ...enLocale
  },
  zh_cn: {
    ...zhCn,
    ...zhLocale
  }
}
let locale = sessionStorage.getItem("lang")||'en_us'
const i18n = new VueI18n({
  locale:locale ,
  messages
})
ElementLocale.i18n((key, value) => i18n.t(key, value))



/**
 * 使用svg sprite
 */
import './assets/icons/index'
import store from './store/index'
Vue.config.productionTip = false

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;


/**
 * 剪切板功能
 */
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

/**
 * 自定义公用组件
 */
import dwButton from './components/dwButton/dwButton'
Vue.component('dwButton', dwButton)


//公共方法库
import $session from './utils/storageManager'
Vue.use($session);

import './utils/util';

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'menu') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
