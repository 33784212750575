function cans() {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var txt = 'http://security.tencent.com/';
    ctx.textBaseline = "top";
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = "tencent";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText(txt, 4, 17);

    var b64 = canvas.toDataURL().replace("data:image/png;base64,", "");
    var bin = atob(b64);
    var crc = bin2hex(bin.slice(-16, -12));
    //var crc = bin.slice(-16,-12);
    return crc;
}

function bin2hex(str) {
    var result = "";
    for (let i = 0; i < str.length; i++) {
        result += int16_to_hex(str.charCodeAt(i));
    }
    return result;
}

function int16_to_hex(i) {
    var result = i.toString(16);
    var j = 0;
    while (j + result.length < 4) {
        result = "0" + result;
        j++;
    }
    return result;
}

function enDate_to_zhDate(date) {
    var m = {
        'Jan': '01',
        "Feb": '02',
        "Mar": '03',
        "Apr": '04',
        "May": '05',
        "Jun": '06',
        "Jul": '07',
        "Aug": '08',
        "Spt": '09',
        "Oct": '10',
        "Nov": '11',
        "Dec": '12'
    };
    let enDate = date;
    let mounth = enDate.slice(0, 3);
    let day = enDate.slice(4, 6);
    let year = enDate.slice(10, 14);
    let hour = enDate.slice(16, 18);
    let min = enDate.slice(19, 21);
    let resultDate = year + '-' + m[mounth] + '-' + day + ' ' + hour + ':' + min;
    resultDate = year + '-' + m[mounth] + '-' + day;  //去掉时间
    return resultDate;
}

function zhDate_to_enDate(date) {
    var dt = new Date(date);
    var m = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec");
    // var w = new Array("Monday", "Tuseday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday");
    var d = new Array("st", "nd", "rd", "th");
    var mn = dt.getMonth();
    // wn = dt.getDay();
    var dn = dt.getDate();
    var hn = dt.getHours();
    var minn = dt.getMinutes();
    var dns;
    if (((dn % 10) < 1) || ((dn % 10) > 3)) {
        dns = d[3];
    }
    else {
        dns = d[(dn % 10) - 1];
        if ((dn == 11) || (dn == 12)) {
            dns = d[3];
        }
    }
    var tns = 'am';
    if (hn >= 12) {
        tns = 'pm';
        if (hn >= 13) {
            hn = hn - 12;
        }
    }
    var result = m[mn] + " " + (dn < 10 ? dn = '0' + dn : dn) + dns + " ," + dt.getFullYear() + " " + (hn < 10 ? hn = '0' + hn : hn) + ':' + (minn < 10 ? minn = '0' + minn : minn) + (tns < 9 ? tns = '0' + tns : tns);
    result = m[mn] + " " + dn + dns + ", " + dt.getFullYear(); //去掉时间
    return result;
}
//  乘法
function NumberMul(arg1, arg2) {
    var m = 0;
    var s1 = arg1.toString();
    var s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) { }
    try {
        m += s2.split(".")[1].length;
    } catch (e) { }

    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
//  加法
function NumberAdd(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
}
function getUrlParam (Address){
    let obj = {};
    let reg = /[?&][^?&]+=[^?&%]+/g;
    let url = Address;
    let arr = url.match(reg);
    arr.forEach((item) => {
        let tempArr = item.substring(1).split('=');
        let key = decodeURIComponent(tempArr[0]);
        let val = decodeURIComponent(tempArr[1]);
        obj[key] = val;
    })
    return obj;
  }
export default {
    cans, enDate_to_zhDate, zhDate_to_enDate, NumberMul, NumberAdd, getUrlParam
}
