<template>
    <div class="chatNews" ref="chatNews">
        <div @click="more()" v-if="isMore" class="top more">
            <span v-if="!loading">{{$t('lang.NText169')}}</span>
            <i v-else class="el-icon-loading"></i>
        </div>
        <div v-if="!isMore && newsArr.length > 15" class="top moreNo">
            <span >{{$t('lang.NText170')}}</span>
        </div>
        <div v-for="(item, index) in newsArr" :key="index" class="box" :id='item.top'>
            <!-- <span v-html="item">['fill', 'contain', 'cover', 'none', 'scale-down'],</span> -->
            <div class="publiClass NewsBox left" v-if="item.sendUserId != userId">
                <div class="title">
                    <img style="width: 42px; margin-top: 7px;" :src="titleImg" alt="">
                </div>
                <div class="content">
                    <p class="name">
                        <span>{{item.sendUserName}}</span>
                        <span class="time">{{(item.createDate.split(' ')[0] == date)? item.createDate.split(' ')[1]:item.createDate}}</span>
                    </p>
                    <p class="info" v-html="item.msgText"></p>
                </div>
            </div>
            <div class="publiClass NewsBox right" v-else>
                <div class="content">
                    <p class="name">
                        <span class="time">{{(item.createDate.split(' ')[0] == date)? item.createDate.split(' ')[1]:item.createDate}}</span>
                        <span>{{item.sendUserName}}</span>
                    </p>
                    <p class="info" v-html="item.msgText"></p>
                </div>
                <div class="title">
                    <img style="width: 42px;" :src="userImg?userImg:headImg" alt="">
                </div>
            </div>
            <!-- <div class="publiClass center">v-if=""</div> -->
        </div>
    </div>
</template>

<script>

export default {
    data() {
        name: 'dwChat'
        return {
            date: this.Data(),
            titleImg: require('../../assets/images/logo_7dc6d5c5.webp'),
            headImg: require('../../assets/images/head.png'),
            userImg: JSON.parse(sessionStorage.getItem('@userInfo'))['avatarUrl'],
            userId: JSON.parse(sessionStorage.getItem('@userInfo'))['id']
        };
    },
    props: {
        newsArr: {
            type: Array,
            default: () => []
        },
        isMore: Boolean,
        loading: Boolean,
    },
    watch: {
        newsArr:function(old, val) {
            // console.log(val)
        }
    },
    components: {
        
    },
    computed: {

    },
    created() {
        // this.date = this.Data();
    },
    updated() {
        this.$nextTick(() => {
            let el = document.getElementsByClassName('chatNews');
            el.scrollTop = el.scrollHeight;
        })
    },
    destroyed() {

    },
    methods: {
        more() {
            this.$emit('more')
        },
        Data() {
            function add(m) {
                return m < 10 ? '0' + m : m
            }
            let today = new Date()
            let year = today.getFullYear()
            let month = today.getMonth()
            let date = today.getDate()
            let time = year + '-' + add((month + 1)) + '-' + add(date)
            return time
        }
    },
    mounted() {

    }
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.chatNews{
    width: 100%;
    height: auto;
    transition: .5s;
    .top{
        font-size: 14px;
        text-align: center;
        box-sizing: border-box;
        padding: 3px 0;
    }
    .more{
        color: #299be4;
        cursor: pointer;
        span{
            &:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    .moreNo{
       color: #ccc; 
    }
    .box{
        margin-bottom: 10px;
        .publiClass{
            width: 100%;
            height: auto;
            min-height: 80px;
        }
        .NewsBox{
            box-sizing: border-box;
            padding-top: 5px;
            .title{
                display: inline-block;
                width: 40px;
                height: 40px;
                overflow: hidden;
                border: 1px solid #ccc;
                border-radius: 50%;
                img{
                    width: 75px;
                }
            }
            .content{
                display: inline-block;
                vertical-align: top;
                width: auto;
                max-width: 55%;
                box-sizing: border-box;
                p{
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .name {
                    color: #666666;
                    margin-bottom: 5px;
                    font-size: 16px;
                    .time{
                        display: inline-block;
                        width: auto;
                        font-size: 12px;
                        transform: translateY(1px);
                    }
                }
                .info {
                    color: #132569;
                    height: auto;
                    min-height: 40px;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    border: 1px solid #ccc;
                    padding: 10px 10px;
                    border-radius: 4px;
                    font-size: 16px;
                    position: relative;
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    display: inline-block;
                    p{
                        text-align: left;
                    }
                }
            }
        }
        .left{
            text-align: left;
            padding-left: 20px;
            .content{
                padding-left: 15px;
                .name {
                    text-align: left;
                    .time{
                        margin-left: 8px;
                    }
                }
                .info {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 10px;
                        left: -10px;
                        width: 0;
                        height: 0;
                        border-right: 10px solid #ccc;
                        border-bottom: 10px solid transparent;
                        border-top: 10px solid transparent;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 11px;
                        left: -9px;
                        width: 0;
                        height: 0;
                        border-bottom: 9px solid transparent;
                        border-right: 9px solid #fff;
                        border-top: 9px solid transparent;
                    }
                }
            }
        }
        .right{
            text-align: right;
            padding-right: 20px;
            .content{
                padding-right: 15px;
                .name {
                    text-align: right;
                    .time{
                        margin-right: 8px;
                    }
                }
                .info {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 10px;
                        right: -10px;
                        width: 0;
                        height: 0;
                        border-left: 10px solid #ccc;
                        border-bottom: 10px solid transparent;
                        border-top: 10px solid transparent;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 11px;
                        right: -9px;
                        width: 0;
                        height: 0;
                        border-bottom: 9px solid transparent;
                        border-left: 9px solid #fff;
                        border-top: 9px solid transparent;
                    }
                }
            }
        }
    }
}
</style>