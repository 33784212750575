<template>
  <div class="dwButton">
    <el-button
      v-if="type == 'addbutton' && isAdd"
      class="addButton"
      @click="click"
      :loading="loading"
      :disabled="disabled"
    >
      <div class="add-button-content">
        <div class="addIcon">+</div>
        <slot></slot>
      </div>
    </el-button>
    <el-button
      v-else-if="type == 'primarySmall'"
      class="primarySmall"
      @click="click"
      :loading="loading"
      :disabled="disabled"
    >
      <slot></slot>
    </el-button>
    <el-button
      v-else-if="type == 'infoSmall'"
      class="infoSmall"
      @click="click"
      :loading="loading"
      :disabled="disabled"
    >
      <slot></slot>
    </el-button>
    <el-button
      v-else-if="type == 'successSmall'"
      class="successSmall"
      @click="click"
      :loading="loading"
      :disabled="disabled"
    >
      <slot></slot>
    </el-button>
    <el-button
      type="primary"
      :loading="loading"
      v-else-if="type == 'primary'"
      @click="click"
      :disabled="disabled"
    >
      <slot></slot>
    </el-button>
    <el-button
      type="success"
      v-else-if="type == 'success'"
      @click="click"
      :loading="loading"
      :disabled="disabled"
    ></el-button>
    <el-button
      type="info"
      class="infoSmall"
      :loading="loading"
      v-else-if="type == 'info'"
      @click="click"
      :disabled="disabled"
      ><slot></slot
    ></el-button>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String },
    isAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.addButton,
.addButton:focus {
  border-width: 2px;
  background: $background2;
  border-color: $background2;
  border-radius: 0;
  height: 64px;
  width: 200px;
  line-height: 64px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  padding: 0;
  font-family: "montserratregular";
}

.primarySmall,
.primarySmall:focus {
  height: 48px;
  padding: 0 40px;
  line-height: 48px;
  border-width: 2px;
  background: $mainColor;
  border-color: $mainColor;
  border-radius: 0;
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font-family: "montserratregular";
}

.infoSmall,
.infoSmall:focus {
  height: 48px;
  padding: 0 40px;
  line-height: 48px;
  border-width: 2px;
  background: #fff;
  border-color: $mainColor;
  border-radius: 0;
  font-size: 18px;
  color: $mainColor;
  text-align: center;
  padding: 0 30px;
  font-family: "montserratregular";
}

.successSmall,
.successSmall:focus {
  height: 48px;
  padding: 0 40px;
  line-height: 48px;
  border-width: 2px;
  background: #fff;
  border-color: $background2;
  border-radius: 0;
  font-size: 18px;
  color: $background2;
  text-align: center;
  padding: 0 30px;
  font-family: "montserratregular";
}

.successSmall:hover {
  background: $background;
  color: $mainColor;
  border-color: $background;
}

.infoSmall:hover {
  background: $background;
  color: $mainColor;
  border-color: $background;
}
.addButton:hover,
.primarySmall:hover {
  background: $background;
  color: $mainColor;
  border-color: $background;
}
.el-button--primary,
.el-button--primary:focus {
  border-width: 2px;
  background: $mainColor;
  border-color: $mainColor;
  border-radius: 0;
  height: 64px;
  width: 200px;
  line-height: 64px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  padding: 0;
  font-family: "montserratregular";
}

.el-button--text:hover {
  color: $mainColor;
}

.el-button--primary:hover {
  background: $background2;
  color: #fff;
  border-color: $background2;
}
.add-button-content {
  font-size: 18px;
  height: 60px;
  display: flex;
  justify-content: center;
  .addIcon {
    font-size: 40px;
    font-weight: 100;
    font-family: "montserratLight";
    margin-right: 10px;
    line-height: 60px;
  }
}
</style>