export default {
    install: (Vue) => {
        Vue.filter('dateFormat', function (str, locale = 'en_us', type = '') {
            if (str && locale != 'zh_cn') {
                let M = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec");
                let d = new Array("st", "nd", "rd", "th");
                let date = new Date(str);
                if (date instanceof Date && !isNaN(date.getTime())) {
                    let yy = date.getFullYear()
                    let mm = date.getMonth();
                    let dd = date.getDate();
                    let hh = date.getHours();
                    let m = date.getMinutes();
                    var dns;
                    if (((dd % 10) < 1) || ((dd % 10) > 3)) {
                        dns = d[3];
                    } else {
                        dns = d[(dd % 10) - 1];
                        if ((dd == 11) || (dd == 12)) {
                            dns = d[3];
                        }
                    }
                    var tns = 'am';
                    if (hh >= 12) {
                        tns = 'pm';
                        if (hh >= 13) {
                            hh = hh - 12;
                        }
                    }
                    if (type == 'hour') {
                        return M[mm] + " " + (dd < 10 ? dd = '0' + dd : dd) + dns + ", " + yy + " " + (hh < 10 ? hh = '0' + hh : hh) + ':' + (m < 10 ? m = '0' + m : m) + ' ' + tns;
                    } else {
                        return M[mm] + " " + dd + dns + ", " + yy; //去掉时间
                    }
                } else {
                    return str
                }
            } else {
                return str
            }
        })
    }
}