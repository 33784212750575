export const lang = {
    to: 'To',
    English: "English",
    Chinese: "Chinese",
    Language: 'Language',
    account: 'Manage account',
    security: 'Security',
    TimeZone: 'Time zone',
    NotificationSetting: 'Notification setting',
    AdvancedSettings: 'More settings',
    ChangePassword: 'Change password',
    ChangeMemberID: 'Change account id',
    getAQuote: "Get a quote",
    newQuote: "New quote",
    Detach: "Detach",
    Detached: "Detached",
    Recover: "Recover",
    QText1: 'Ocean freight',
    QText2: 'Air freight',
    QText3: 'eCommerce',
    QText4: 'End to end solution',
    QText5: 'Quote from previous quotation',
    QText6: 'Quote from  booking',
    QText7: 'Quote from template',
    QText8: 'Quote',
    QText9: 'From-To',
    QText10: 'Date',
    QText11: '',
    QText12: 'Details& services',
    QText13: 'From',
    QText14: 'To',
    QText15: 'CY',
    QText16: 'Door',
    QText17: 'Orignal port',
    QText18: 'Destination port',
    QText19: 'Next',
    QText20: 'Date',
    QText21: 'Cargo ready day',
    QText22: 'Devliery day',
    QText23: 'Select a date',
    QText24: 'Preivious',
    QText25: '',
    QText26: 'Container',
    QText27: 'Cargo',
    QText28: 'Total weight',
    QText29: 'Total volume',
    QText30: 'Total quantity',
    QText31: 'Shipment contain following goods',
    QText32: 'Batteries',
    QText33: 'Hazardous materials',
    QText34: 'Other(creams, liquids,powders)',
    QText35: 'Details& Services',
    QText36: 'optional',
    QText37: 'Skip',
    QText38: 'Incoterms',
    QText39: 'Payment',
    QText40: 'Prepaid',
    QText41: 'Collect',
    QText42: 'Cargo details',
    QText43: 'Cargo type',
    QText44: 'Ordinary',
    QText45: 'Dangerous',
    QText46: 'Temperature control',
    QText47: 'Commodity',
    QText48: 'Origin services',
    QText49: 'Pick up',
    QText50: 'Address',
    QText51: 'Leave a message or something',
    QText52: 'Country or region',
    QText53: 'State',
    QText54: 'City',
    QText55: 'Zip code',
    QText56: 'Customer clearance',
    QText57: 'Insurance',
    QText58: 'Value',
    QText59: 'Currency',
    QText60: 'ISF',
    QText61: 'Documentation',
    QText62: 'ACI',
    QText63: 'Warehouse',
    QText64: 'Value added service',
    QText65: 'Cosolidation',
    QText66: 'Destination services',
    QText67: 'Delivery',
    QText68: 'Customer clearance',
    QText69: 'Bond',
    QText70: 'Single bond',
    QText71: 'Annual bond',
    QText72: 'Warehouse',
    QText73: 'Storage',
    QText74: 'Transload',
    QText75: 'Drop-shiping',
    QText76: 'Review',
    QText77: 'Edit',
    QText78: 'Save as template',
    QText79: 'Cancel',
    QText80: 'Ok',
    QText81: 'Quotation Review',
    QText82: 'Quotation ID',
    QText83: 'No quotation information',
    QText84: 'Task log',
    QText85: 'Inquiry',
    QText86: 'Quotation',
    QText87: 'Chat history',
    QText88: 'Upload offline quotation',
    QText89: 'Inquiry edit',
    QText90: 'My template',
    QText91: 'No',
    QText92: 'Template name',
    QText93: '长度最多 10 个字符',
    QText94: 'The order is in quotation and cannot be modified.',
    QText95: 'Expired',
    QText96: 'Range from 0 to 10000000, with three decimal places reserved',
    QText97: 'Share management',
    QText97: 'Share management',
    QText98: 'Shared with',
    QText99: 'Shared from',
    QText100: 'Confidential',
    QText101: 'Pending',
    QText102: 'Accepted',
    QText103: 'Rejected',
    QText104: "Are you sure you don't share the quotation anymore?",
    QText105: "You don't have partner to share.",





    text1: 'Old password',
    text2: 'Password',
    text2new: 'New password',
    text3: 'Repeat password',
    text4: "密码必须介于8到18个字符之间,包含大小写字母和数字,不能包含特殊字符和用户名",
    text7: "Passwords does not match",
    text8: "Required",
    text10: "Your Account ID",
    text11: "Your new Account ID",
    text12: "Verification code",
    text13: "Next",
    text14: "Submit",
    text15: "Send E-mail",
    text16: "The email address cannot be a de-well address.",
    text17: "The E-mail address is invalid.",
    logout: "Sign off",
    text18: "sending...",
    delete: "Are you sure to Delete?",
    Urgent: "Are you sure you want to urgent this quotation?",
    yes: "Yes",
    lastSignIn: "Last sign in",
    currentSignIn: "Current sign in",
    Admin: "Admin",
    Staff: "Staff",



    message1: "If the account is not activated, activate the account first.",


    BText1: 'Filter',
    BText2: 'Status',
    BText3: 'Consignee',
    BText4: 'Summary',
    BText5: 'Create date',
    BText6: 'Draft',
    BText7: 'Template',
    BText8: 'Type',
    BText8N: 'Type',
    BText9: 'Status',
    BText10: 'Submit',
    BText11: 'Audit',
    BText12: 'Authorize',
    BText12S: 'waiting for your authorization',
    BText13: 'Price confirmed',
    BText14: 'Shipping',
    BText15: 'Cancelled',
    BText16: 'Cargo info.',
    BText17: 'Shipper',
    BText18: 'View',
    BText19: 'Are you sure to leave?',
    BText20: 'Task log',
    BText21: 'Quotation',
    BText22: 'Notify party',
    BTextNotification: 'Notification',
    BText23: 'Origin',
    BText24: 'Origin port',
    BText25: 'Customs clearance',
    BText26: 'Destination',
    BText27: 'Discharge port',
    BText28: 'Delivery day',
    BText29: 'Containers',
    BText30: 'Dangerous goods',
    BText31: 'Notification',
    BText32: 'Pricing & booking comfirmation',
    BText33: 'My draft',
    BText34: 'Create booking',
    BText35: 'Booking',
    BText36: 'Draft summary',
    BText37: 'Cargo Info.',
    BText38: 'Last update',
    BText39: 'Created by',
    BText40: 'Delete',
    BText41: 'Yo! Leave a message, or something...',
    BText42: 'Add new commodity',
    BText43: 'Total',
    BText44: 'Packing list',
    BText45: 'PO No.',
    BText46: 'Qty',
    BText47: 'Volume',
    BText48: 'Weight',
    BText49: 'Add new group',
    BText50: 'Carton',
    BText51: 'Marks',
    BText52: 'Shipment contain following goods ?',
    BText53: 'Just my account',
    BText54: 'Multi-party cooperation',
    BText55: 'Rename',
    BText56: 'Create from recent booking',
    BText57: 'Create from draft',
    BText58: 'Create from template',
    BText59: 'Create from quotation',
    BText60: 'Refresh',
    BText61: 'Manage',
    BText62: 'Created',
    BText63: 'Task ID',
    BText64: 'Quotation ID',
    BText65: 'Pricing comfirm',
    BText66: 'Booking ID',
    BText67: 'Audit',
    BText68: 'Creating Pricing',
    BText69: 'Authorize',
    BText70: 'Shipping authorize',
    BText71: 'No quotation information',
    BText72: 'Upload offline quotation document',
    BText73: 'Upload',
    BText74: 'Word,Excel,PDF,JPG,PNG file',
    BText75: 'New booking',
    BText75V: 'Booking',
    BText76: 'Or',
    BText77: 'Customer booking reference No',
    BText78: 'Reference No.',
    BText79: 'Services',
    BText80: 'Select',
    BText81: 'Select a date',
    BText82: 'Container type',
    BText83: 'Add a new type',
    BText84: 'HS code',
    BText85: 'Carton Qty.',
    BText86: 'Package volume',
    BText87: 'Dimensions',
    BText88: 'L',
    BText89: 'W',
    BText90: 'H',
    BText91: 'Booking information',
    BText92: 'E-mail',
    BText93: 'Maximum 5 E-mail address,Please use","separate.',
    BText94: 'Origin charge',
    BText95: 'Freight & Destination Charge',
    BText96: 'Save draft',
    BText97: 'Previous',
    BText98: 'No.',
    BText99: 'Are you sure to delete this?',
    BText100: 'Draft summry',
    BText101: 'Save draft in slot',
    BText102: 'and overwrite the old one?',
    BText103: 'Delivery day',
    BText104: 'Cargo information',
    BText105: 'Update date',
    BText106: 'Booking type',
    BText107: 'Port',
    BText108: 'Search',
    BText109: 'More',
    BText110: 'Booking review',
    BText111: 'Linked Quote ID',
    BText112: 'Update',
    BText113: 'Expired date',
    BText114: 'Quick fill in',
    BText115: 'Are you sure to shipping authorize this booking?',
    BText116: 'Are you sure to cancel this booking?',
    BText117: 'Link your online quotation',
    BText118: 'Amount',
    BText119: 'Freight',
    BText120: 'Apply',
    BText121: 'Skip',
    BText122: 'Shipping authorization',
    BText123: 'Save template in slot',
    BText124: 'Services',
    BText125: 'Services',


    SText1: 'Inactivated',
    SText2: 'Welcome',
    SText3: 'Manage your information, privacy, and security.',
    SText4: 'Last update password',
    SText5: 'First name',
    SText6: 'Last name',
    SText7: 'Account ID (E-mail)',
    SText8: 'Not verified',
    SText9: 'Verify now',
    SText10: 'Mobile number',
    SText11: 'Please input the correct mobile number',
    SText12: 'Phone number',
    SText13: 'Save',
    SText14: 'Profile photo',
    SText15: 'Safety advice',
    SText16: 'Verify your E-mail',
    SText17: 'Your device',
    SText18: 'This device',
    SText19: 'Manage your device',
    SText20: 'Change Account ID',
    SText21: 'Send Verification E-mail',
    SText22: 'Manage my device',
    SText23: 'The device you have signed in to',
    SText24: 'Sign out of account',
    SText25: 'Sign out of your account from',
    SText26: 'Your password have been Changed.',
    SText27: 'Please sign in again.',
    SText28: 'Automatically enter the sign in page after',
    SText29: 's',
    SText30: 'Sign in',
    SText31: 'Verify account ID',
    SText32: 'Set new account ID',
    SText33: 'Wait',
    SText34: 'to send again',
    SText35: 'Your new account ID have been set.',
    SText36: 'Please sign in with',
    SText37: 'Automatically enter the sign in page after',
    SText38: 'Address',
    SText3811: 'address',
    SText39: 'Address tag',
    SText40: 'New address',
    SText41: 'Are you sure to delete?',
    SText42: 'System',
    SText43: 'Customized',
    SText44: 'New tag',
    SText45: 'Do you want to delete the',
    SText46: 'tag ?',
    SText47: 'Create customized tag.',
    SText48: 'Tag name',
    SText49: 'Partner',
    SText50: 'Tag',
    SText51: 'Contact',
    SText52: 'Name',
    SText53: 'Phone',
    SText54: 'Bill of lading format',
    SText55: 'Contact',
    SText56: 'Admin',
    SText57: 'Origin business',
    SText58: 'Destination Business',
    SText59: 'Accounting',
    SText60: 'Edit Contact',
    SText61: 'Staff',
    SText62: 'Or fill in',
    SText63: 'First Name',
    SText64: 'Last Name',
    SText65: 'Email',
    SText66: 'Country Or Region',
    SText67: 'Mobile',
    SText68: 'My company name',
    SText69: 'Company name',
    SText70: '中文公司名称',
    SText71: 'Company address',
    SText72: 'Company info',
    SText73: 'Company name (English)',
    SText74: 'Company name (Chinese)',
    SText75: 'GST No.',
    SText76: 'Bank',
    SText77: 'Account No.',
    SText78: 'Deactivate',
    SText79: 'APPROVED',
    SText80: 'Locked',
    SText81: 'Deactivated',
    SText82: 'NEW',
    SText83: 'Invoice title',
    SText84: 'Invoice address',
    SText85: 'My bank name',
    SText86: 'Add new',
    SText87: 'Payee',
    SText88: 'Invoice Title',
    SText89: 'Bank name',
    SText90: 'Account',
    SText91: 'My account',
    SText92: 'My staff',
    SText93: 'My partner',
    SText94: 'Tracking',
    SText95: 'Any quotation notification, you want to notify:',
    SText96: 'Select notify party',
    SText97: 'Notify party quick view',
    SText98: 'My Partner',
    SText99: 'Inviting',
    SText100: 'Invited',
    SText101: 'Closed',
    SText102: 'Invite Partner',
    SText103: 'Partner name & ID',
    SText104: 'Invite date',
    SText105: 'Role',
    SText106: 'Cancel invitation',
    SText107: 'Send invitation',
    SText108: 'Send invitation',
    SText109: 'Edit partner',
    SText110: 'Send partner invitation to',
    SText111: 'Partner name',
    SText112: 'Account ID or E-mail',
    SText113: 'Partner role',
    SText114: 'Send',
    SText115: 'Do you want to cancel the invitation of',
    SText116: 'Default password',
    SText117: 'Change default password',
    SText118: 'All new staff can sign in with the default password in the first time.',
    SText119: 'They can change it to their own password in account management.',
    SText120: 'Normal',
    SText121: 'New staff',
    SText122: 'Reset password',
    SText123: 'Verify account',
    SText124: 'Close account',
    SText125: 'Copy successfully',
    SText126: 'Please select a type',
    SText127: 'Features not developed',
    SText128: 'Please input Shipper',
    SText129: '长度在 5 到 200 个字符',
    SText130: 'Please input Consignee',
    SText131: 'Please input NotifyParty',
    SText132: 'Please input Container',
    SText133: 'Please input Commodity',
    SText134: 'Please do not fill in negative numbers',
    SText135: 'Please input Total volume',
    SText136: 'Please input Total weight',
    SText137: 'Please input Total quantity',
    // SText138:'Please input Origin port',
    SText139: 'Please select Destination or discharge port',
    SText140: 'Please select CargoType',
    SText141: 'Booking edit',
    SText142: 'Please input Template names',
    SText143: 'Please input Template name',
    SText144: '哎呦出错了！',
    SText145: 'Sign out successfully',
    SText146: 'Edit address',
    SText147: 'Add address',
    SText148: '长度最多 30 个字符',
    SText149: 'Delete successfully',
    SText150: 'Please input name',
    SText151: 'Save successfully',
    SText152: 'Please select partner',
    SText153: 'Please select address tag',
    SText154: '长度最多 20 个字符',
    SText155: 'Please input Phone',
    SText156: 'Please enter the correct phone number',
    SText157: 'Please input address',
    SText158: '长度最多 150 个字符',
    SText159: 'Please select City',
    SText160: 'Please select State',
    SText161: 'Please select Country or region',
    SText162: '只能数字',
    SText163: '不可有空格',
    SText164: '长度在 1 到 20 个字符',
    SText165: 'Please input First name',
    SText166: 'Please input Last name',
    SText167: 'Please input E-mail',
    SText168: '电子邮件地址无效',
    SText169: '长度在 1 到 50 个字符',
    // SText170:'Please input Mobile',
    // SText171:'Please input Phone',
    SText172: '长度在 5 到 50 个字符',
    SText173: '长度在 5 到 150 个字符',
    // SText174:'Please select country or region',
    // SText175:'Please select state',
    // SText176:'Please select city',
    SText177: 'Please input zip code',
    SText178: 'Please input company name(English)',
    SText179: 'Please input company name(Chinese)',
    SText180: 'Please input address',
    SText181: '长度在 0 到 20 个字符',
    SText182: 'Please input invoice title',
    SText183: 'Please input GST No.',
    SText184: 'Please input bank name',
    SText185: 'Please input account No.',
    SText186: 'Cancel successfully',
    SText187: '长度介于8到18个字符之间,必须包含大小写字母和数字，不能包含特殊字符',
    SText188: 'Please input password',
    SText189: '请输入其他的邮箱地址',
    SText190: 'Please input Account ID (E-mail)',
    SText191: '长度最多 50 个字符',
    SText192: '请输入正确的邮箱地址',
    SText193: 'Add staff',
    SText194: 'Are you sure to reset ',
    SText197: "'s  password?",
    SText195: 'Are you sure to verify ',
    SText198: "'s  account?",
    SText196: 'Are you sure to close ',
    SText199: 'Are you sure to recover ',



    NText10: 'Subject',
    NText9: 'Create date',
    NText8: 'Send to',
    NText7: 'All',
    NText6: 'Read',
    NText5: 'Unread',
    NText4: 'Dear',
    NText3: 'Congratulations',
    NText1: 'You have signed up sucessfully. Your Account ID is:',
    NText2: 'Our support team will contact you within 48 hours (working days).',
    NText11: 'Create time',
    NText12: 'Send by',
    NText13: 'Please verify your email address by clicking the button below',
    NText14: 'Confirm',
    NText15: 'Button not working? Paste the following link into your browser.',
    NText16: 'This link will be expired in',
    NText17: 'To get a new verification E-mail, visit',
    NText18: 'If you did not sign up for Link Me Account ID, please ignore this e-mail.',
    NText19: 'Link Me Support Team',
    NText20: 'Your Link Me BMS account has been set up. Your Account ID is:',
    NText21: 'hours',
    // NText22:'We heard that you lost your Link Me Account ID password. Sorry about that!',
    NText23: "We heard that you lost your Link Me Account ID password. Sorry about that! But don't worry! You can use following link to reset your password.",
    NText24: 'Reset password',
    NText25: 'If you did not ask for reset password, please ignore this e-mail.',
    NText26: 'You changed your password at',
    // NText27:"If you didn't, please reset your account password immediately.",
    NText28: "If it`s not your action,your account may have been compromised.Please contact with Link Me support team.",
    NText29: 'on',
    NText30: 'We heard that you want to change your Link Me Account ID.',
    NText31: 'Your verification code is:',
    NText32: 'This code will be expired in',
    NText33: 'If you did not ask for verification, please ignore this e-mail.',
    NText34: 'You Account ID have been changed successfully.',
    NText35: 'Now, your Account ID is:',
    NText36: 'If you did not ask for changing Account ID, please ignore this e-mail.',
    NText37: 'We noticed that your Link Me Account ID failed to sign in for many times Since',
    NText38: 'Do you forgot your password? You can use following link to reset your password.',
    NText39: 'If you are not trying to log in to your account.Please keep your account safe.',
    NText40: 'invites you to be an online partner as',
    NText49: 'Accept',
    NText48: 'Deny',
    NText47: 'invites you to be an online partner as',
    NText46: 'We noticed that you are not our Link Me BMS user yet. If you are willing to accept the invitation, Please follow the steps below',
    NText45: 'accepted your invitation.',
    NTextCompanynameen: 'Company name(en):',
    NTextCompanynamecn: 'Company name(cn):',
    NTextAccount: 'Account ID:',
    NTextRole: 'Role:',
    NText44: 'is your online partner as',
    NText43: 'refused your invitation.',
    NText42: 'Welcome on board',
    NText41: 'Your account has been activated.',
    NText51: 'All functions of Link Me BMS are good to go.',
    NText52: 'Please check the quotation. Quotation ID:',
    NText53: 'Quotation ID:',
    NText54: 'Related Quotation ID:',
    NText541: 'Your booking information was updated by Line Me operation team.',
    NText55: 'related Quotation ID:',
    NText56: 'was updated, please check.',
    NText57: 'will be expired on',
    NText58: 'Link Me operation team created 1 quotation inquiry request for you.',
    NText59: 'Your partner',
    NText50: 'shared a quotation with you.',
    NText60: 'your sharing quotation.',
    NText69: 'Quotation ID:',
    NText691: 'Booking ID:',
    NText68: 'Your booking audit was passed, your shipping journey will start once you finished authorization in Link Me.',
    NText681: "Your booking's quotation was generated, your shipping journey will start once you finished authorization in Link Me.",
    NText67: 'Your booking is shipping.',
    NText671: 'You can use Shipment ID to track the shipping milestone in Link Me.',
    NText66: 'Booking ID:',
    NText65: '.Shipment ID:',
    NText64: 'Link Me operation team created 1 booking request for you.',
    NText63: 'Booking ID:',
    NText62: 'According to your requirement, We modified',
    NText61: 'Your booking information was updated by Link Me operation team. And your updated booking information no longer match your previous quotation. Link Me auto generate the new quotation inquiry request.',
    NText70: 'Linked Quote ID:',
    NText71: 'Update:',
    NText72: 'Expiry date:',
    NText73: 'Quotation review',
    NText74: 'ID:',
    NText75: 'Update date:',
    NText76: 'Delivery day:',
    // NText77:'Expiry date:',
    NText78: 'Charge item',
    NText79: 'Price',
    NText80: 'Qty.',
    NText81: 'Cur.',
    NText82: 'Terms',
    NText83: 'Task Log',
    NText84: 'Details',
    NText85: 'Sharing quotation',
    NText86: 'Reject',
    NText87: 'Offline quotation document',
    NText88: 'Cargo infomations',
    NText89: 'Type & Status',
    // NText90:'Last update Date',
    NText91: 'Task ID',
    NText92: 'Link ID',
    NText93: 'Last update date',
    NText94: 'Process',
    NText95: '不能为空',
    NText96: 'Please select Container type',
    NText97: 'Please input Notify party',
    NText98: 'Please select Incoterms',
    // NText99:'Please select trade clause',
    NText100: 'Please select Origin port',
    NText101: 'Please select Cargo ready day',
    NText102: 'Please input Pick up address',
    NText103: 'Please input Value',
    NText104: 'Please select Currency',
    NText105: 'Please select Value added service',
    NText106: 'Please select Discharge port',
    NText107: 'Please select Delivery day',
    NText108: 'Please input Delivery address',
    // NText109:'Please select Cargo type',
    NText110: 'Please select Bond',
    // NText111:'Please input total quantity',
    // NText112:'Please input total volume',
    // NText113:'Please input total weight',
    // NText114:'Please select incotermsType',
    // NText115:'Please select trade clause',
    NText116: 'Please input Partner name',
    NText117: 'Please input Account ID or E-mail',
    NText118: 'Please select Partner role',
    // NText119:'Please select country',
    NText120: 'Please input Mobile',
    NText121: 'Shipment',
    NText122: 'MBL No.',
    NText123: 'Carrier',
    NText124: 'Vessel & Voyage',
    NText125: 'HBL No.',
    NText126: 'Incoterm',
    NText127: 'Shipment Chassis Days',
    NText128: 'Shipment Per Diem Days',
    NText129: 'PO Reference',
    NText130: 'Cargo Release Type',
    NText131: 'R.F Cargo',
    NText132: 'Container No.',
    NText133: 'Seal No.',
    NText134: 'Mile stone',
    NText135: 'Current',
    NText136: 'No data',
    NText137: 'Unit',
    NText138: 'Description',
    NText139: 'Last free day',
    NText140: 'Download',
    NText141: 'Shipment ID',
    NText142: 'Shipment Type',
    NText143: 'Notify party',
    NText144: 'ETA',
    NText145: 'ETD',
    NText146: 'Review',
    NText147: 'Edit staff',
    NText148: 'Routing Schedule',
    NText149: 'Carrier & Vessel Voyage',
    NText150: 'Load port',
    NText151: 'Track',
    NText152: 'Results found',
    NText153: 'Estimate',
    // NText154:'Date not found',
    NText155: 'Dashboard',
    NText156: 'New quotation',
    NText156V: 'Quotation',
    NText157: 'Waiting for your confirmation',
    NText158: 'Process now',
    NText159: 'Coming soon',
    NText160: 'New shipment',
    NText161: 'Volume',
    NText162: 'Cost',
    NText163: 'Select photo',
    NText164: 'Preview',
    NText165: 'OK',
    NText166: '图片类型要求：jpeg、jpg、png',
    NText167: '图片大小要求：< 5MB',
    NText168: '图片分辨率要求：200px*200px',
    NText169: '查看更多消息',
    NText170: '暂无消息',
    NText171: 'Document',
    NText172: 'Link',
    NText173: 'related chatting records',
    NText174: 'Online chatting',
    NText175: 'Setting',
    NText176: 'Are you sure to sign off?',
    NText177: 'Original port',
    NText178: 'Pick up address',
    NText179: 'Please select destination port',
    NText180: 'Destination address',
    NText181: '[Optional]',
    NText182: 'Value added services',
    NText183: 'Please select value added services',
    // NText184:'Please select bond type',
    NText185: 'Please select activity zone',
    NText186: 'PO No.',
    NText187: 'Pallet',
    NText188: 'Inquiry review',
    NText189: 'New inquiry',
    NText190: 'Your inquiry has not been completed yet.',
    NText191: 'Do you want to leave ? Current inquiry information will be lost.',
    NText192: 'Leave',
    NText193: 'No services',
    NText194: 'Inquiry date',
    NText195: 'Urgent',
    NText196: 'Reply date',
    NText197: 'Share',
    NText198: 'Generate booking based on',
    NText199: 'Items which are related with rate element will be locked in you booking',
    NText200: 'Such as',
    NText201: 'Origin port / Discharge port / Services / Container type',
    NText202: 'Continue',
    NText203: 'Confidentiality clause',
    NText204: 'All open',
    NText205: 'Keep origin services rate confidential',
    NText206: 'Keep freight & destination services rate confidential',
    NText207: 'All confidential',
    NText208: 'Share quotation',
    NText209: 'End date',
    NText300: 'Start date',
    NText301: 'Replied date',
    NText302: 'Clear',
    NText303: 'eDocs',
    NText304: 'Do you accept the quotation',
    NText305: 'Invoice',
    NText306: 'Bill of landing',
    NText307: 'Others',
    NText308: 'My document',
    NText309: 'System document',
    NText310: 'Document name',
    NText311: 'Document states',
    NText312: 'Effective',
    NText313: 'invalid',
    NText314: 'Quotation template',
    NText315: 'Origin total',
    NText316: 'Freight total',
    NText317: 'Destination total',
    NText318: 'Charge name',
    NText319: 'Memo',
    NText320: '可能产生费用',
    NText321: 'New default password',
    NText322: 'Document name',
    NText323: 'Success',
    NText324: 'Error',
    NText325: 'Sign Off',
    NText326: 'Template edit',
    NText327: 'Accept invitation',
    NText328: 'Partner',
    NText329: 'Account ID',
    NText330: 'Role',
    NText331: 'Cancel',


    UserGuide: 'User Guide',
    Section: 'Section',
    Start: 'Start',
    dontShow: "Don't show me again",
}
