<template>
  <div class="left-menu">
    <div class="menu-title">
      <span>Link Me</span>
    </div>
    <div class="left-menu-list">
      <div class="menu-left">
        <div
          class="menu-left-line regula30"
          :class="{ choose: menu && item.menuName == menu.menuName }"
          :key="key1"
          v-for="(item, key1) in menuList"
          @mouseover="clickMenu(item)"
          @click="chooseMenu(item)"
          v-show="item.menuName !== 'Settings' && item.extend.extendS1 == 'bms'"
        >
          <!-- <router-link :to="item.menuUrl"> -->
          <div class="name" :class="{ fontSice: item.menuName.length > 15 }">
            <span v-if="$i18n.locale == 'en_us'">{{ item.menuName }}</span>
            <span v-if="$i18n.locale == 'zh_cn'">{{
              item.extend.extendS3
            }}</span>
            <svg-icon
              v-show="
                item.menuName == menu.menuName && menu.childList.length > 0
              "
              icon-class="into"
              class="into-icon"
            ></svg-icon>
          </div>

          <!-- </router-link> -->
        </div>
      </div>
      <div class="menu-right" v-if="menu && menu.childList">
        <div
          class="menu"
          v-for="(value2, key2) in menu.childList"
          :key="key2"
          :class="{ lastMenu: key2 == menu.childList.length - 1 }"
          @click="chooseMenu(value2)"
        >
          <router-link :to="value2.menuUrl">
            <div :key="key2"  :class="{ fontSice: value2.menuName.length > 15 }">
              <span v-if="$i18n.locale == 'en_us'">{{ value2.menuName }}</span>
              <span v-if="$i18n.locale == 'zh_cn'">{{
                value2.extend.extendS3
              }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="Setting" @click="toSetting">
      <div class="icon">
        <svg-icon icon-class="setting"></svg-icon>
      </div>
      <div>{{ $t("lang.NText175") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // menuList: {
    //   type: Array,
    // },
  },
  data() {
    return {
      menu: [],
      menuList: [],
    };
  },
  created() {
    window.removeEventListener("menu", () => {
      this.menu = JSON.parse(sessionStorage.getItem("menu"));
    });
    window.addEventListener("menu", () => {
      this.menu = JSON.parse(sessionStorage.getItem("menu"));
    });
    this.$session("menuList").get((value) => {
      this.menuList = value;
    });
    // this.menuList = this.$attrs.menuList;
  },
  methods: {
    clickMenu(menu) {
      this.menu = menu;
    },
    Fun(item) {
      let str;
      switch (item) {
        case "Get a quote":
          str = "0";
          break;
        case "My quotation":
          str = "1";
          break;
        default:
          str = "2";
      }
      return str;
    },
    chooseMenu(item) {
      sessionStorage.setItem("menu", JSON.stringify(item));
      if (item.menuName == "Quotation") {
        this.$emit("closeMask");
        sessionStorage.setItem("menu", JSON.stringify(item.childList[0]));
        let obj = JSON.parse(sessionStorage.getItem("@menuList")).find(
          (res) => {
            return res.menuName == "Quotation";
          }
        );
        sessionStorage.setItem("tabList", JSON.stringify(obj.childList));
        this.$router.push("/Quotation/GetQuote");
        this.$emit("menuInfo");
        return;
      }
      if (
        item.menuName == "Get a quote" ||
        item.menuName == "My quotation" ||
        item.menuName == "Template"
      ) {
        sessionStorage.setItem("menu", JSON.stringify(item));
        let obj = JSON.parse(sessionStorage.getItem("@menuList")).find(
          (res) => {
            return res.menuName == "Quotation";
          }
        );
        sessionStorage.setItem("tabList", JSON.stringify(obj.childList));
        // this.$emit('activeIndexFun',this.Fun(item.menuName))
      }
      // this.$store.commit("api/SET_MENU", this.menu);
      this.$emit("closeMask");
      sessionStorage.setItem("kSecondHeader", "0");
      this.$router.push(item.menuUrl);
      this.$emit("menuInfo");

      // sessionStorage.setItem('headerLeft', item.menuName);
    },
    toSetting() {
      this.menuList.forEach((res) => {
        if (res.menuName == "Settings") {
          res.childList.forEach((req) => {
            if (req.menuName == "Account") {
              sessionStorage.setItem("menu", JSON.stringify(req.childList[0]));
              sessionStorage.setItem("tabList", JSON.stringify(req.childList));
              this.$emit("menuInfo");
            }
          });
          this.$router.push({ path: "/Settings/Account" });
          this.$emit("closeMask");
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.left-menu {
  background-color: $mainColor;
  border-top: 1px solid rgba($color: $mainColor, $alpha: 0.5);
  position: absolute;
  top: 140px;
  bottom: 0;
  font-family: "montserratLight";
  width: 640px;
  .menu-title {
    background-color: $background;
    color: $mainColor;
    font-size: 60px;
    line-height: 100px;
    text-align: left;
    padding-left: 30px;
    font-family: "montserratregular";
  }

  .Setting {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    color: #fff;
    line-height: 80px;
    font-size: 30px;
    display: flex;
    font-family: "montserratregular";
    background-color: rgba($color: #fff, $alpha: 0.5);
    cursor: pointer;
    .icon {
      background-color: rgba($color: #fff, $alpha: 0.5);
      width: 80px;
      margin-right: 20px;
      .svg-icon {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  .left-menu-list {
    width: 640px;
    color: #fff;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    top: 100px;
    bottom: 80px;

    .menu-left {
      width: 300px;
      .menu-left-line {
        height: 80px;
        line-height: 80px;
        padding-left: 30px;
        text-align: left;
        border-right: 1px solid rgba($color: $mainColor, $alpha: 0.2);
        font-size: 30px;
        .fontSice{
          font-size: 10px;
        }
        .name {
          cursor: pointer;
          position: relative;
          svg {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .into-icon {
          fill: #fff;
          stroke: #fff;
          height: 30px;
          width: 13px;
          margin-right: 30px;
        }
        a {
          color: #fff;
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .menu-left-line:hover {
        background-color: rgba($color: #fff, $alpha: 0.5);
      }
      .choose {
        background-color: rgba($color: #fff, $alpha: 0.5) !important;
      }
    }

    .menu-right {
      background-color: rgba($color: #fff, $alpha: 0.5);
      width: 340px;
      position: absolute;
      top: 0;
      left: 300px;
      line-height: 78px;
      font-size: 24px;
      padding-bottom: 2px;
      .menu {
        margin: 0 50px;
        text-align: left;
        border-bottom: 2px solid rgba($color: #fff, $alpha: 0.5);
         .fontSice{
          font-size: 20px;
        }
        a {
          color: #fff;
          text-decoration: none;
        }
        a:hover {
          color: $mainColor;
          text-decoration: none;
        }
      }

      .lastMenu {
        border: 0;
      }
    }
  }
}
</style>
