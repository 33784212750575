import axios from "axios";
import qs from "qs";
import global from './global'
import Cookies from "js-cookie"
import $session from './storageManager/storageManager'

const request = axios.create({
  baseURL: `${global.baseUrl}`,
  timeout: 7200000,
  transformRequest: [
    (data) => {
      return data instanceof FormData ? data : Qs.stringify({ //此处的data类型判断为重要点，
        ...data
      });
    }
  ]
});  
//请求拦截
request.interceptors.request.use(config => {
	let lang = sessionStorage.getItem('lang','');
    let token = sessionStorage.getItem('token');
    config.data.append('dlg', global.islg)
    config.data.append('lg',lang)
    config.data.append('lang',lang)
    // config.data.append('__sid', token)
    return config;
  },
  error => Promise.error(error)
);

request.interceptors.response.use(// 响应拦截器
  async function (res) {
      if (res.status === 200) {
        return Promise.resolve(res.data);
      } else {       
        return Promise.reject(res.data);
      }
    },
    async function (error) {
      const {
        response
      } = error;
      if (response) {      
        return Promise.reject(response);
      } else {        
      }
    }
);
export default request;