/**
 * 基于 aladdin.util开发
 * 作者 : 朱柯欣
 *
 * TODO 使用范例
 * 引用依赖库
 * import storageManager from '@util/storageManager' //存储管理
 * storageManager.config({
 *	module: 'module1', //库名称,项目的访问的存储 默认空字符串
 * 	splitChar: '@', //分割符号,连接拓展名与键名 默认@
 *	debug: false, //调试模式 默认false
 * });
 * //设置session
 * storageManager.session('key').set('value',() => {
 * 	//to do some thing...
 * });
 * //获取session
 * storageManager.session('key').get((value)=>{
 * 	//to do some thing...
 * 	console.log(value);
 * });
 * //获取其他库的session
 * storageManager.getModule('module1').session('info').get((value)=>{
 * 	to do some thing...
 *  console.log(value);
 * });
 */
 const Params = {
    name: 'storageManager',
    version: '1.0.0',
    types: ['session', 'local']
  },
  Config = { //默认参数
    module: '',
    splitChar: '@',
    debug: false,
  },
  storage = {
    'session': sessionStorage,
    'local': localStorage
  };
/**
 * 公共异常
 */
function commException() {
  let fnName = arguments[0],
    msgStr = arguments[1];
  //如果msg没有传,则直接提示消息
  if (!msgStr) {
    return ['[', Params.name, ']', ' ', fnName].join('');
  } else {
    return ['[', Params.name, ']', ' ', fnName, '->>', msgStr].join('');
  }
}

/**
 * 尝试转换json
 * @param {String} jsonStr
 */
function tryJson(jsonStr) {
  try {
    jsonStr = JSON.parse(jsonStr);
  } catch (e) {
    //出错说明不是json 不做任何操作
  }
  return jsonStr;
}
/**
 * 尝试转换将json转换为jsonString
 * @param {JSON} json
 */
function tryJsonStr(json) {
  try {
    json = JSON.stringify(json);
  } catch (e) {
    //发生错误说明不是json
    commException('tryJsonStr', 'json is not a jsonString or string');
  }
  return json;
}

function storageManager() {
  //配置信息
  this._config = Config;
  //版本信息
  this._version = Params;
}

/**
 * 配置当前存储管理的库信息
 * @argument {Object} params 库名String or 配置信息Json
 * 配置信息格式
 * 	module 库名称,项目的访问的存储 默认空字符串
 * 	splitChar 分割符号,连接拓展名与键名 默认@
 * 	debug 调试模式 默认false
 */
storageManager.prototype.config = function () {
  //如果没有参数 则不执行
  if (!arguments || arguments.length <= 0) {
    throw commException('config', 'The parameter may be empty. Please check.');
  }
  let _config;
  //如果第0位是string 则传的是库名
  if (typeof arguments[0] === 'string') {
    _config = {
      module: arguments[0]
    };
  } else {
    _config = arguments[0];
  }
  Object.assign(Config, _config);
  //将当前返回
  return this;
}

/**
 * 获取其他存储管理库 参数参照config方法
 * @param {String} module 库名 需要加载的库的名称
 * @param {String} splitChar 分割符号
 */
storageManager.prototype.getModule = function (module, splitChar) {
  if (typeof module !== 'string') {
    throw commException('getModule', 'module must be a string.');
  }
  if (splitChar && typeof splitChar !== 'string') {
    throw commException('getModule', 'splitChar must be a string.');
  }
  let _params = JSON.parse(JSON.stringify(Config)),
    _config = {
      module
    };
  //如果分割符存在 则赋值分隔符
  if (splitChar) {
    _config.splitChar = splitChar;
  }
  //将主库的其他
  Object.assign(_params, _config);
  try {
    return new storageManager().config(_params);
  } catch (e) {
    throw commException('getModule', e);
  }
  return this;
}

//遍历类型 创建方法
Params.types.forEach((item, index) => {
  storageManager.prototype[item] = function (key) {
    if (!key || typeof key !== 'string') {
      throw new commException(item, 'key can not null.');
    }
    const _self = this,
      storageKey = [Config.module, Config.splitChar, key].join('');
    return {
      get: (cb) => {
        if (cb && typeof cb !== 'function') {
          throw new commException(item, 'get cb must be a function.');
        }
        let value = storage[item].getItem(storageKey);
        //要数据是存在的,都尝试转换为json输出 如果不是直接输出
        if (value) {
          value = tryJson(value);
        }
        cb && cb(value);
        Config.debug && console.log(commException(item + ' get:' + storageKey), value);
        return _self;
      },
      set: (value, cb) => {
        if (cb && typeof cb !== 'function') {
          throw new commException(item, 'set cb must be a function.');
        }
        try {
          //如果存入的数据不是string 那可能是jsonObj 尝试转换为string
          if (typeof value != 'string') {
            value = tryJsonStr(value);
          }
          storage[item].setItem(storageKey, value);
          Config.debug && console.log(commException(item + ' set:' + storageKey), value);
        } finally {
          setTimeout(() => {
            cb && cb();
          }, 10);
        }
        return _self;
      },
      remove: (cb) => {
        if (cb && typeof cb !== 'function') {
          throw new commException(item, 'remove cb must be a function.');
        }
        try {
          storage[item].removeItem(storageKey);
          Config.debug && console.log(commException(item + ' remove:' + storageKey));
        } finally {
          setTimeout(() => {
            cb && cb();
          }, 10);
        }
        return _self;
      }
    }
  }
  //注册清除方法
  storageManager.prototype[item].clear = function (cb) {
    Config.debug && console.warn(commException(item + ' clear all'));
    storage[item].clear();
    setTimeout(() => {
      cb && cb();
    }, 10);
  }
});



export default new storageManager();