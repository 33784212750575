<template>
  <div class="menuList">
    <!--logo-->
    <div class="imgBox">
      <img class="logo" src="../../assets/images/logo@3x.png">
    </div>
    <div class="closeBox" :class="{showClose: childMenuList.length}" @click="childMenuList = []">
      <svg-icon class="close" icon-class="close" />
    </div>
    <!--菜单list-->
    <ul class="list">
      <li class="list-item" v-for="(item, index) of menuList.length ? menuList : iconList" :key="index"
          :class="{setting: index == iconList.length-1 || item.menuName == 'Settings', activeMenu: activeMenu == index}" @click="selectMenu(item, index)">
        <el-tooltip v-show="activeMenu == index" :content="item.menuName" placement="right" effect="light">
          <svg-icon class="list-item-icon" :icon-class="item.icon"></svg-icon>
        </el-tooltip>
        <el-tooltip v-show="activeMenu != index" :content="item.menuName" placement="right" effect="light">
          <img class="list-item-icon" :src="item.iconImg" alt=""/>
        </el-tooltip>
      </li>
    </ul>
    <ul class="child-list" :class="{hasChildList: childMenuList.length}">
      <li class="parentTitle">
        {{ parentTitle }}
      </li>
      <li class="child-list-item" v-for="(item, index) of childMenuList" :key="index" @click="selectMenu(item, activeMenu)">
        <span v-if="$i18n.locale == 'en_us'">{{ item.menuName }}</span>
        <span v-if="$i18n.locale == 'zh_cn'">{{
            item.extend.extendS3
          }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'kMenu',
  props: {
    menuList: {
      type: Array
    },
    activeMenuIndex: {
      type: Number
    }
  },
  watch: {
    menuList: {
      handler: function(val) {
        if(val.length) {
          let [itm, idx] = [val[0], 0]
          val.forEach((item, index) => {
            item.icon = this.iconList[index].icon
            item.iconImg = this.iconList[index].iconImg
            if(item.childList) {
              item.childList.forEach((im, ix) => {
                if(im.menuUrl == this.$route.path) {
                  itm = im
                  idx = index
                }
              })
            } else {
              if(item.menuUrl == this.$route.path) {
                itm = item
                idx = index
              }
            }
          })
          this.selectMenu(itm, idx)
        }
      },
      deep: true
    },
    activeMenuIndex: {
      handler: function(val) {
          this.activeMenu = val
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      iconList: [
        {
          icon: 'iconPark-analysis',
          iconImg: require('../../assets/images/menu-icon1.png')
        },
        {
          icon: 'riLine-calculator-line',
          iconImg: require('../../assets/images/menu-icon2.png')
        },
        {
          icon: 'iconPark-inbox-r Copy',
          iconImg: require('../../assets/images/menu-icon3.png')
        },
        {
          icon: 'iconPark-road',
          iconImg: require('../../assets/images/menu-icon4.png')
        },
        {
          icon: 'iconPark-calendar',
          iconImg: require('../../assets/images/menu-icon5.png')
        },
        {
          icon: 'iconPark-local-two',
          iconImg: require('../../assets/images/menu-icon6.png')
        },
        {
          icon: 'ze-balance-list-o',
          iconImg: require('../../assets/images/menu-icon7.png')
        },
        // {
        //   icon: 'iconPark-internal-data Copy',
        //   iconImg: require('../../assets/images/menu-icon8.png')
        // },
        {
          icon: 'setting',
          iconImg: require('../../assets/images/menu-icon9.png')
        }
      ],
      activeMenu: 0,
      parentTitle: '',
      childMenuList: []
    }
  },
  methods: {
    close() {
      this.childMenuList = []
    },
    selectMenu(val, index) {
      this.activeMenu = index
      this.$emit('setActiveMenuIndex', index)
      // 复制过来的方法
      if(!val.childList.length) {
        this.childMenuList = []
        sessionStorage.setItem("menu", JSON.stringify(val));
        if (val.menuName == "Quotation") {
          this.$emit("closeMask");
          sessionStorage.setItem("menu", JSON.stringify(val.childList[0]));
          let obj = JSON.parse(sessionStorage.getItem("@menuList")).find(
              (res) => {
                return res.menuName == "Quotation";
              }
          );
          sessionStorage.setItem("tabList", JSON.stringify(obj.childList));
          this.$router.push("/Quotation/GetQuote");
          this.$emit("menuInfo");
          return;
        }
        if (
            val.menuName == "Get a quote" ||
            val.menuName == "My quotation" ||
            val.menuName == "Template"
        ) {
          sessionStorage.setItem("menu", JSON.stringify(val));
          let obj = JSON.parse(sessionStorage.getItem("@menuList")).find(
              (res) => {
                return res.menuName == "Quotation";
              }
          );
          sessionStorage.setItem("tabList", JSON.stringify(obj.childList));
          // this.$emit('activeIndexFun',this.Fun(val.menuName))
        }
        // this.$store.commit("api/SET_MENU", this.menu);
        this.$emit("closeMask");
        sessionStorage.setItem("kSecondHeader", "0");
        this.$router.push(val.menuUrl);
        this.$emit("menuInfo");
      } else {
        this.parentTitle = val.menuName
        this.childMenuList = val.childList
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/base.scss";

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuList {
  height: 100%;
  position: relative;
  z-index: 1;
  .imgBox {
    width: 60px;
    height: 60px;
    background: $mainColor1;
    display: grid;
    place-items: center;
    position: relative;
    left: 0;
    top: 0;
    z-index: 2;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.4);
    .logo {
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  .closeBox {
    width: 60px;
    height: 60px;
    background: $mainColor1;
    display: grid;
    place-items: center;
    position: absolute;
    left: 0;
    top: -60px;
    z-index: 2;
    transition: top 0.6s;
    cursor: pointer;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.4);
    .close {
      display: block;
      width: 30px;
      height: 30px;
      color :#ffffff;
      fill: #ffffff;
      stroke: #ffffff;
    }
  }

  .showClose{
    top: 0
  }

  .list {
    height: calc(100% - 60px);
    position: absolute;
    overflow-y: auto;
    padding-bottom: 60px;
    box-sizing: border-box;
    z-index: 2;
    background: $mainColor;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.4);
    .list-item {
      width: 60px;
      height: 60px;
      display: grid;
      place-items: center;

      .list-item-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .activeMenu {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 17px;
        height: 26px;
        width: 5px;
        background: $activeColor
      }
    }

    .setting{
      position: fixed;
      bottom: 0;
      background: $mainColor;
      .list-item-icon {
        stroke: #ffffff;
        fill: #ffffff;
      }
    }
  }

  .child-list{
    width: 300px;
    height: 100%;
    background: #ffffff;
    position: absolute;
    left: -300px;
    top: 0;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.4);
    transition: left 0.6s;
    li {
      width: 100%;
      padding-left: 18px;
      box-sizing: border-box;
      text-align: start;
    }
    .parentTitle{
      height: 60px;
      background: #9AB3CD;
      color: #ffffff;
      font-size: 20px;
      line-height: 60px;
    }
    .child-list-item {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      color: $mainColor;
      background: #efefef;
      cursor: pointer;
    }
  }

  .hasChildList{
    left: 60px;
  }
}
</style>
