/**
 * 配置服务端接口
 * @type {{claz_controller: {saveClaz: string, putOff: string, getClazDetail: string, getClazList: string, putOn: string}}}
 */
const source = {
    logout: "/a/logout",
    account_api: {
        getUserInfo: "/account/user/info",
        menuTree: "/account/bms/menuTree",
        changepwd: "/account/bms/saveChangePwd",
        changeIDCode: "account/bms/getCheckEmailCode",
        checkIDCode: "account/bms/CheckEmailCode",
        changeIDCodeNext: "/account/bms/getCheckNewEmailCode",
        checkIDCodeNext: "/account/bms/changeNewLoginCode",
        checkMembereEmail: "/account/checkMembereEmail",
        updateMemberInfo: "/account/updateMemberInfo",
        uploadAvatar: "/account/uploadAvatar",
        uploadFile: "/account/bms/uploadFile",
        toSendMsg: "/bms/chat/toSendMsg",
        chatList: "/bms/chat/msgList",
        sendMsg: "/bms/chat/sendMsg",
        getChatHistory: "/chat/getChatHistory",
        refreshChatHistory: "/chat/refreshChatHistory",
        getIncomeDataList: "/crm/crmProfit/getIncomeDataList",
        getTotalByCustomer: "/crm/crmProfit/getTotalByCustomer",
        indexTeu: "/crm/crmProfit/indexTeu",
        indexTeuByMonth: "/crm/crmProfit/indexTeuByMonth",

    },
    basApi_api: {
        timeZoneList: "/basApi/timeZone/timeZoneList",
        customerTeuList: "/basApi/dashboard/customerTeuList"
    },
    device_api: {
        listData: "/login/device/listData",
        delRecord: "/login/device/delRecord",
        delAutomaticLogon: "/login/device/delAutomaticLogon"
    },
    address_api: {
        addAddress: "/address/data/add",
        editAddress: "/address/data/edit",
        delAddress: "/address/data/del",
        addressList: "/address/all/list",
        tagList: "/address/tag/data",
        delTag: "/address/tag/del",
        addTag: "/address/tag/add",
        select: "/address/tag/select",
        getCountryList: "/address/tertiary/one",
        getStateList: "/address/tertiary/two",
        getCityList: "/address/tertiary/three",
        phone: "/address/tertiary/phone",
        parterSelect: "/address/partner/select",
        fill: "address/history/fill"
    },
    quotation_api: {
        findPortsList: "/bms/dwBmsQuotation/findPortsList",
        findContainerTypeList: "/bms/dwBmsQuotation/findContainerTypeList",
        findCargoTypeList: "/bms/dwBmsQuotation/findCargoTypeList",
        findWeightUnitList: "/bms/dwBmsQuotation/findWeightUnitList",
        findVolumeUnitList: "/bms/dwBmsQuotation/findVolumeUnitList",
        findCargoUnitList: "/bms/dwBmsQuotation/findCargoUnitList",
        findWarehouseList: "/bms/dwBmsQuotation/findWarehouseList",
        saveQuotation: "/bms/dwBmsQuotation/saveQuotation",
        findCurrentyList: "/bms/dwBmsQuotation/findCurrentyList",
        findTemplateList: "/bms/dwBmsQuotation/findTemplateList",
        templateRename: "/bms/dwBmsQuotation/templateRename",
        findIncotermsData: "/bms/dwBmsQuotation/findIncotermsData",
        deleteQuotation: "/bms/dwBmsQuotation/deleteQuotation",
        urgentQuotation: "/bms/dwBmsQuotation/urgentQuotation",
        saveForTemplate: "/bms/dwBmsQuotation/saveForTemplate",
        shareQuotation: "/bms/dwBmsQuotation/shareQuotation",
        quotationListData: "/bms/dwBmsQuotation/quotationListData",
        findQuotationType: "/bms/dwBmsQuotation/findQuotataionType",
        findCommodityTypeList: "/bms/dwBmsQuotation/findCommodityTypeList",
        findPartnerList: "/bms/dwBmsQuotation/findPartnerList",
        confirmQuotationCharges: "/bms/dwBmsQuotation/confirmQuotationCharges",
        comfirmShareQuotation: "/bms/dwBmsQuotation/confirmShareQuotation",
        uploadFile: "/crm/edoc/upload",
        downloadPdf: "/crm/crmQuotation/downloadPdf",
        edoclist: "/crm/edoc/list",
        downloadedoc: "/crm/edoc/download",
        deleteFile: "/crm/edoc/delete",
        getIsLock: "/bms/dwBmsQuotation/getIsLock",
        findQuoteConfirmCount: "/bms/dwBmsQuotation/findQuoteConfirmCount",
        findPackUnit: "/bms/dwBmsQuotation/findPackUnit",
        findVolumeUnitListForTotal: "/bms/dwBmsQuotation/findVolumeUnitListForTotal",
        findCargoUnitListForTotal: "/bms/dwBmsQuotation/findCargoUnitListForTotal",
        findShareQuoteList: "/bms/dwBmsQuotation/findShareQuoteList",
        cancellShareQuoate: "/bms/dwBmsQuotation/cancellShareQuoate",
        editQuotation: "/bms/dwBmsQuotation/editQuotation",
    },
    booking_api: {
        addBooking: "/bms/dwBmsBookingMain/addBooking",
        bookingUpdate: "/bms/dwBmsBookingMain/bookingUpdate",
        bookingList: "/bms/dwBmsBookingMain/bookingList",
        bookingDetails: "/bms/dwBmsBookingMain/bookingDetails",
        templateList: "/bms/dwBmsBookingMain/templateList",
        deleteTemplate: "/bms/dwBmsBookingMain/deleteTemplate",
        updateTemplateName: "/bms/dwBmsBookingMain/updateTemplateName",
        addTemplateDraft: "/bms/dwBmsBookingMain/addTemplateDraft",
        draftList: "/bms/dwBmsBookingMain/draftList",
        draftTemplatesDetails: "/bms/dwBmsBookingMain/draftTemplatesDetails",
        bookingView: "/bms/dwBmsBookingMain/bookingView",
        bookingStatus: "/bms/dwBmsBookingMain/bookingStatus",
        userAuthorization: "/bms/dwBmsBookingMain/userAuthorization",
        bookingCancel: "/bms/dwBmsBookingMain/bookingCancel",
        findBookingConfirmCount: "/bms/dwBmsBookingMain/findBookingConfirmCount",
        findLock: "/bms/dwBmsBookingMain/findLock",
        bookingActivation: "/bms/dwBmsBookingMain/bookingActivation"
    },
    partner_api: {
        listData: "/bms/partner/listData",
        save: "/bms/partner/save",
        update: "/bms/partner/update",
        sendInvitation: "/bms/partner/sendInvitation",
        rejectInvating: "/bms/partner/rejectInvating",
        cancelInvitation: "/bms/partner/cancelInvitation",
        removePartner: "/bms/partner/removePartner"
    },
    staff_api: {
        listData: "/staff/all/list",
        add: "/staff/data/add",
        update: "/staff/data/update",
        close: "/staff/data/close",
        verify: "/staff/data/verify",
        reset: "/staff/data/reset",
        show: "/staff/password/show",
        password: "/staff/update/password",
        recovery: "/staff/data/recovery"
    },
    notification_api: {
        listData: "/bms/dwBmsNotificationSettings/listData",
        dwBmsNotificationSettings: "/bms/dwBmsNotificationSettings/settings",
        getPartnerNames: "/bms/dwBmsNotificationSettings/getPartnerNames",
        getStaffNames: "/bms/dwBmsNotificationSettings/getStaffNames",
        listDataIndex: "/bms/dwBmsNotification/listData",
        details: "/bms/dwBmsNotification/details",
        unReadNumber: "/bms/dwBmsNotification/unReadNumber"
    },
    basCompany_api: {
        list: "/bms/basCompany/list",
        edit: "/bms/basCompany/edit",
        verification: "/bms/basCompany/verification",
        history: "/bms/basCompany/history",
        contactlist: "/bms/contact/list",
        staffData: "/bms/contact/staffData",
        staffDataedit: "/bms/contact/edit",
        payee: "/bms/payee/list",
        update: "/bms/payee/update",
        insert: "/bms/payee/insert",
        stop: "/bms/payee/stop",
        delete: "/bms/payee/delete"
    },
    tracking_api: {
        queryTracking: '/api/bms/queryTracking',
        trackingList: '/basApi/dashboard/trackingList',
    },
    shipment_api: {
        listData: "/bms/shipment/listData",
        view: "/bms/shipment/view",
        conlistData: "/bms/shipment/conlistData",
        portList: "/bms/shipment/portList",
    },
    task_api: {
        taskSelect: "/bms/task/taskSelect",
        taskList: "/bms/task/list",
        tasklog: "/bms/task/log"
    }
};

export default source;
