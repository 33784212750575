//全局配置
//前端环境

let lan = navigator.systemLanguage || navigator.language;
export const islg = (lan && lan.toLowerCase().indexOf('zh')!==-1)? "zh_cn" : "en_us";
const baseUrl='https://test-lm-api.link-tech.com';// 测试环境
const dewellUrl = "https://test.link-tech.com"; // 测试

const url = {
    baseUrl :baseUrl,
    dewellUrl: dewellUrl,
    islg:islg
}

export default url
