import axios from "axios";
import qs from "qs";
import global from './global'
import Cookies from "js-cookie"
import $session from './storageManager/storageManager'

axios.defaults.timeout = 7200000;
// axios.defaults.headers.post['Access-Control-Allow-Origin']='*';               //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';        //配置请求头
axios.defaults.baseURL = `${global.baseUrl}`;   //配置接口地址
// axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'http://192.168.1.200:8980';
// axios.defaults.baseURL = 'http://192.168.1.118:8980';
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
	// console.log(config)
	//在发送请求之前做某件事
	var lang = ""
	var lang = sessionStorage.getItem('lang', '');
	// var token = sessionStorage.getItem('token', '');
	var token = null;
	$session.session('token').get((value) => {
		token = value;
	})
	var loc = Cookies.get('token');
	token = token ? token : loc;
	// if(config.method  === 'post' && config.headers.post['Content-Type'] == 'application/x-www-form-urlencoded;charset=utf-8'){
		config.data = {
			...{},
			...config.data
		}
		
	if (config.method === 'post') {
		if (config.data.__sid == '' || config.data.__sid == null || typeof (config.data.__sid) == "undefined" || config.data.__sid == undefined) {
			config.data.__sid = token
		}
		config.data.dlg = global.islg;
		config.data.lg = lang;
		config.data.lang = lang;
		config.data = qs.stringify(config.data);
	} else {
		if (config.params.__sid == '' || config.params.__sid == null || typeof (config.params.__sid) == "undefined" || config.params.__sid == undefined) {
			config.params.__sid = token
		}
		config.params.dlg = global.islg;
		config.params.lang = lang;
		config.params.lg = lang;
	}
	return config;

}, (error) => {
	// console.log('错误的传参')
	return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
	//对响应数据做些事
	if (!res.data.success && res.data.message != '未登录') {
		return Promise.resolve(res);
	}
	return res;
}, (err) => {
	console.log(err)
	if (err && err.response) {
		switch (err.response.status) {
			case 400:
				err.message = '请求错误'
				break
			case 401:
				err.message = '未授权，请登录'
				break
			case 403:
				err.message = '拒绝访问'
				break
			case 404:
				err.message = `请求地址出错: ${err.response.config.url}`
				break
			case 408:
				err.message = '请求超时'
				break
			case 500:
				err.message = '服务器内部错误'
				break
			case 501:
				err.message = '服务未实现'
				break
			case 502:
				err.message = '网关错误'
				break
			case 503:
				err.message = '服务不可用'
				break
			case 504:
				err.message = '网关超时'
				break
			case 505:
				err.message = 'HTTP版本不受支持'
				break
			default:
		}
	}
	return Promise.reject(err);
});


//返回一个Promise(发送post请求)
export function cPost(url, params) {
	return new Promise((resolve, reject) => {
		// console.info('cPost')
		// console.log(params)
		// console.log(params.__sid)
		axios.post(url, params)
			.then(response => {
				resolve(response);
			}, err => {
				reject(err);
			})
			.catch((error) => {
				reject(error)
			})
	})
}
export function cPosts(url, params) {
	return new Promise((resolve, reject) => {
		// console.info('cPosts')
		// console.info(url)
		// console.info(params)
		axios.post(url, params, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		})
			.then(response => {
				resolve(response);
			}, err => {
				reject(err);
			})
			.catch((error) => {
				reject(error)
			})
	})
}
////返回一个Promise(发送get请求)
export function cGet(url, param) {
	return new Promise((resolve, reject) => {
		axios.get(url, { params: param })
			.then(response => {
				resolve(response)
			}, err => {
				reject(err)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
export default {
	cPost,
	cGet,
	cPosts
}