const state = {
    menu: [],
    tabList: [],
    secondMenu: [],
    showTop: true,
    loading: false,
    shipmentData: {}
    // roles: ['9999']
};

const mutations = {
    SET_MENU: (state, menu) => {
        state.menu = menu;
    },
    SET_TAB: (state, tabList) => {
        state.tabList = tabList;
    },
    SET_SECONDMENU: (state, secondMenu) => {
        state.secondMenu = secondMenu;
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_LOADING: (state, loading) => {
        state.loading = loading;
    },
    SET_SHIPMENT: (state, shipmentData) => {
        state.shipmentData = shipmentData;
    },
    // SET_NAME: (state, name) => {
    //     state.name = name;
    // },
    // SET_ROLES: (state, roles) => {
    //     state.roles = roles;
    // },
    // SET_TENANTID: (state, roles) => {
    //     state.tenantId = roles;
    // },
    // SET_USER_INFO: (state, userInfo) => {
    //     state.userInfo = userInfo;
    // }
};

const actions = {
    // 获取密钥
    getKey({  }) {
        // return new Promise((resolve, reject) => {
        //     getKey()
        //         .then((response) => {
        //             resolve(response);
        //         })
        //         .catch((error) => {
        //             reject(error);
        //         });
        // });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};