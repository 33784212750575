<template>
  <div class="userGuide">
    
    <div class="userGuideMain">
        <quotation></quotation>
    </div>
  </div>
</template>

<script>
import quotation from "./quotation.vue";
export default {
  components: {
    quotation,
  },
};
</script>

<style lang="scss">
.userGuide {
  //   display: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //   height: 100%;
}
#guideMask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.75);
}
.userGuideMain {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
}
</style>