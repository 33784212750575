export default {
    routeConfig: [
        {
            path: '/',
            name: 'index',
            component: () => import('@/view/index')
        }, {
            path: '/login',
            name: 'login',
            component: () => import('@/view/login')
        }, {
            path: '/Settings/Account',
            name: 'Settings',
            component: () => import('@/view/Settings/Account/Home')
        }, {
            path: '/Settings/Account/information',
            name: 'information',
            component: () => import('@/view/Settings/Account/info')
        }, {
            path: '/Settings/Account/Security',
            name: 'Security',
            component: () => import('@/view/Settings/Account/Security')
        }, {
            path: '/Settings/Account/Privacy',
            name: 'Privacy',
            component: () => import('@/view/Settings/Account/Privacy')
        }, {
            path: '/Settings/Staff',
            name: 'Staff',
            component: () => import("@/view/Settings/Staff/index")
        },{
            path: '/Settings/Staff/password',
            name: 'defaultPassword',
            component: () => import("@/view/Settings/Staff/defaultPassword")
        }, {
            path: '/Settings/Partner',
            name: 'Partner',
            component: () => import("@/view/Settings/Partner/index")
        }, {
            path: '/Settings/Notification',
            name: 'NotificationSetting',
            component: () => import("@/view/Settings/Notification/index")
        }, {
            path: '/Settings/Roles',
            name: 'Roles',
            component: () => import("@/view/Settings/Roles/index")
        }, {
            path: '/Settings/Finance',
            name: 'Finance',
            component: () => import("@/view/Settings/Finance/index")
        }, {
            path: '/Settings/Company/Company',
            name: 'Company',
            component: () => import("@/view/Settings/Company/index")
        }, {
            path: '/Quotation/GetQuote',
            name: 'GetQuote',
            component: () => import('@/view/Quotation/aQuote/index')
        }, {
            path: '/Quotation/Create',
            name: 'Create',
            component: () => import('@/view/Quotation/aQuote/create')
        }, {
            path: '/Quotation/Review',
            name: 'Review',
            component: () => import('@/view/Quotation/aQuote/Review')
        }, {
            path: '/Quotation/Edit',
            name: 'Edit',
            component: () => import('@/view/Quotation/aQuote/edit')
        }, {
            path: '/Booking',
            name: 'Booking',
            component: () => import("@/view/Booking/index")
        }, {
            path: '/Booking/Draft',
            name: 'BookingDraft',
            component: () => import("@/view/Booking/draft")
        }, {
            path: '/Booking/Template',
            name: 'BookingTemplate',
            component: () => import("@/view/Booking/template")
        },
        {
            path: '/Tracking',
            name: 'Tracking',
            component: () => import("@/view/Tracking/index")
        },
        {
            path: '/Task',
            name: 'Task',
            component: () => import("@/view/Task/index")
        },
        {
            path: '/Notification',
            name: 'Notification',
            component: () => import("@/view/Notification/index")
        },
        {
            path: '/Quotation/quotation',
            name: 'quotation',
            component: () => import("@/view/Quotation/list/myQutation")
        },
        {
            path: '/Settings/Address/CompanyAddress',
            name: 'CompanyAddress',
            component: () => import("@/view/Settings/Address/CompanyAddress")
        },
        {
            path: '/Settings/Address/PartnerAddress',
            name: 'PartnerAddress',
            component: () => import("@/view/Settings/Address/PartnerAddress")
        },
        {
            path: '/Settings/Address/AddressTag',
            name: 'AddressTag',
            component: () => import("@/view/Settings/Address/AddressTag")
        },
        {
            path: '/Quotation/quotationTemplate',
            name: 'quotationTemplate',
            component: () => import("@/view/Quotation/template/template")
        },
        {
            path: '/Settings/Company/Contactor',
            name: 'Contactor',
            component: () => import("@/view/Settings/Company/Contactor")
        },
        {
            path: '/Settings/Company/Payee',
            name: 'Payee',
            component: () => import("@/view/Settings/Company/Payee")
        },
        {
            path: '/Quotation/shareManagement',
            name: 'shareManagement',
            component: () => import("@/view/Quotation/share/shareMA")
        },
        {
            path: '/Shipment',
            name: 'Shipment',
            component: () => import("@/view/Shipment/index"),
            children: [{
                path: 'basicInfo',
                name: 'basicInfo',
                component: () => import("@/view/Shipment/basicInfo")
            },{
                path: 'Routing',
                name: 'Routing',
                component: () => import("@/view/Shipment/routing")
            },{
                path: 'Container',
                name: 'Container',
                component: () => import("@/view/Shipment/container")
            },{
                path: 'eDoc',
                name: 'eDoc',
                component: () => import("@/view/Shipment/eDoc")
            }]
        },
    ]
}
