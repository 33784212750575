export const lang = {
    to: '至',
    English: "英文",
    Chinese: "中文",
    Language: '语言',
    account: '账户管理',
    security: '安全',
    TimeZone: '时区',
    NotificationSetting: '通知管理',
    AdvancedSettings: '高级设置',
    ChangePassword: '修改密码',
    ChangeMemberID: '修改账号ID',
    getAQuote: "询价",
    newQuote: "创建新询价",
    Detach: "解除关系",
    Detached: "已解除",
    Recover: "恢复账号",
    QText1: '海运',
    QText2: '空运',
    QText3: '跨境电商',
    QText4: '端到端物流解决方案',
    QText5: '从历史报价创建询价单',
    QText6: '从历史订舱创建询价单',
    QText7: '从模板创建询价单',
    QText8: '询价',
    QText9: '起始-到达',
    QText10: '日期',
    QText11: '',
    QText12: '细节&服务',
    QText13: '起始地',
    QText14: '目的地',
    QText15: '到港',
    QText16: '到门',
    QText17: '出发港',
    QText18: '目的港',
    QText19: '下一步',
    QText20: '日期',
    QText21: '货好日期',
    QText22: '出运日期',
    QText23: '选择日期',
    QText24: '上一步',
    QText25: '',
    QText26: '箱型箱量',
    QText27: '货物',
    QText28: '总重量',
    QText29: '总体积',
    QText30: '总数量',
    QText31: '非普货声明',
    QText32: '带电池产品',
    QText33: '危险物品',
    QText34: '其他（膏状物，液体，粉末等）',
    QText35: '细节&服务',
    QText36: '可选',
    QText37: '跳过',
    QText38: '国际贸易条款',
    QText39: '付款方式',
    QText40: '预付',
    QText41: '到付',
    QText42: '货物详细信息',
    QText43: '货物类型',
    QText44: '普货',
    QText45: '危险品',
    QText46: '温控品',
    QText47: '品名',
    QText48: '启运港服务',
    QText49: '取货',
    QText50: '地址',
    QText51: '填写详细地址信息',
    QText52: '国家/地区',
    QText53: '省/州',
    QText54: '城市',
    QText55: '邮编',
    QText56: '报关',
    QText57: '保险',
    QText58: '价值',
    QText59: '币种',
    QText60: 'ISF',
    QText61: '文件',
    QText62: 'ACI',
    QText63: '仓库服务',
    QText64: '增值服务',
    QText65: '集货',
    QText66: '目的港服务',
    QText67: '送货',
    QText68: '清关',
    QText69: 'Bond',
    QText70: '一次性bond',
    QText71: '年度bond',
    QText72: '海外仓服务',
    QText73: '存储',
    QText74: '转运',
    QText75: '一件代发',
    QText76: '预览',
    QText77: '编辑',
    QText78: '存为模板',
    QText79: '放弃',
    QText80: '确认',
    QText81: '报价预览',
    QText82: '报价单号',
    QText83: '无报价信息',
    QText84: '任务日志',
    QText85: '询价信息',
    QText86: '报价单',
    QText87: '聊天记录',
    QText88: '上传报价单',
    QText89: '编辑询价单',
    QText90: '我的模板',
    QText91: '序号',
    QText92: '模板名称',
    QText93: '长度最多 10 个字符',
    QText94: '订单正在报价中，无法编辑',
    QText95: '过期',
    QText96: '范围在0-10000000，并保留三位小数',
    QText97: '分享管理',
    QText98: '分享给',
    QText99: '分享来源',
    QText100: '保密协议',
    QText101: '处理中',
    QText102: '同意',
    QText103: '拒绝',
    QText104: '是否确认不再分享运价？',
    QText105: "您没有可以分享的合作伙伴。",





    text1: '旧密码',
    text2: '密码',
    text2new: '新密码',
    text3: '重复新密码',
    text4: "密码必须介于8到18个字符之间,包含大小写字母和数字,不能包含特殊字符和用户名",
    text7: "密码不匹配",
    text8: "必填",
    text10: "登录账号",
    text11: "新登录账号",
    text12: "验证码",
    text13: "下一步",
    text14: "提交",
    text15: "发送邮件",
    text16: "邮箱不能是de-well邮箱",
    text17: "电子邮件地址无效",
    logout: "登出",
    text18: "发送中",
    delete: "确定要删除吗？",
    Urgent: "你确定要加急此询价单吗？",
    yes: "确定",
    lastSignIn: "上次登录",
    currentSignIn: "当前登录",
    Admin: "管理员",
    Staff: "员工",


    message1: "账户未激活，请先激活账户！",


    BText1: '筛选',
    BText2: '状态',
    BText3: 'Consignee',
    BText4: '概要',
    BText5: '创建时间',
    BText6: '草稿',
    BText7: '模板',
    BText8: '类型',
    BText8N: '通知类型',
    BText9: '状态',
    BText10: '已提交',
    BText11: '报价中',
    BText12: '待确认',
    BText12S: '待授权',
    BText13: '已确认',
    BText14: '出运',
    BText15: '完成',
    BText16: '货物信息',
    BText17: 'Shipper',
    BText18: '预览',
    BText19: '您确定离开当前页面吗？',
    BText20: '任务日志',
    BText21: '报价单',
    BText22: 'Notify party',
    BTextNotification: '通知',
    BText23: '始发地',
    BText24: '启运港',
    BText25: '报关',
    BText26: '目的地',
    BText27: '卸货港',
    BText28: '交货日期',
    BText29: '箱型箱量',
    BText30: '危险品',
    BText31: '通知',
    BText32: '价格和订舱确认 ',
    BText33: '我的草稿',
    BText34: '立即订舱',
    BText35: '订舱',
    BText36: '草稿概要',
    BText37: '货物信息',
    BText38: '最后更新日期',
    BText39: '创建者',
    BText40: '删除',
    BText41: '填写详细地址信息',
    BText42: '新增品名',
    BText43: '总计',
    BText44: '装箱单',
    BText45: '装箱单号',
    BText46: '数量',
    BText47: '体积',
    BText48: '重量',
    BText49: '新增',
    BText50: '箱',
    BText51: '备注',
    BText52: '非普货声明',
    BText53: '仅自己',
    BText54: '多方合作',
    BText55: '重命名',
    BText56: '从历史运单创建',
    BText57: '从草稿创建',
    BText58: '从模板创建',
    BText59: '从历史报价创建',
    BText60: '刷新',
    BText61: '管理',
    BText62: '创建',
    BText63: '任务ID',
    BText64: '报价单 ID',
    BText65: '确认价格',
    BText66: '订舱 ID',
    BText67: '审核',
    BText68: '创建价格',
    BText69: '授权',
    BText70: '授权出运',
    BText71: '暂无报价信息',
    BText72: '上传线下报价文件',
    BText73: '上传',
    BText74: 'Word,Excel,PDF,JPG,PNG 类型文件',
    BText75: '新建订舱',
    BText75V: '订舱',
    BText76: '或',
    BText77: '客户编号信息',
    BText78: '客户编号',
    BText79: '服务',
    BText80: '选择',
    BText81: '选择日期',
    BText82: '箱型',
    BText83: '新增箱型',
    BText84: '海关编码',
    BText85: '数量',
    BText86: '包装尺寸',
    BText87: '包装体积',
    BText88: '长',
    BText89: '宽',
    BText90: '高',
    BText91: '订单信息',
    BText92: '邮箱',
    BText93: '最多填写5个邮箱，并用","分隔。',
    BText94: '起运港费用',
    BText95: '运费&目的港费用',
    BText96: '保存草稿',
    BText97: '上一步',
    BText98: '编号',
    BText99: '是否确认要删除',
    BText100: '概要',
    BText101: '确认保存草稿在栏位',
    BText102: '并覆盖旧的内容？',
    BText103: '到货日期',
    BText104: '货物信息',
    BText105: '更新日期',
    BText106: '订舱类型',
    BText107: '港口',
    BText108: '查找',
    BText109: '更多',
    BText110: '预览',
    BText111: '相关报价单号',
    BText112: '更新',
    BText113: '截止有效期',
    BText114: '快速填写',
    BText115: '是否确认授权出运?',
    BText116: '是否确认取消订舱?',
    BText117: '关联报价单',
    BText118: '金额',
    BText119: '运费',
    BText120: '应用',
    BText121: '跳过',
    BText122: '授权出运',
    BText123: '保存模板到栏位',
    BText124: '启运港服务',
    BText125: '卸货港服务',


    SText1: '未激活',
    SText2: '欢迎',
    SText3: '管理您的账号信息、隐私设置及安全设置。',
    SText4: '上一次更改密码',
    SText5: '姓',
    SText6: '名',
    SText7: '账号ID (邮箱)',
    SText8: '未验证',
    SText9: '验证',
    SText10: '手机',
    SText11: '请填写正确的手机号',
    SText12: '电话',
    SText13: '保存',
    SText14: '头像',
    SText15: '安全性建议',
    SText16: '验证邮箱',
    SText17: '您的设备',
    SText18: '当前设备',
    SText19: '管理设备',
    SText20: '更改账户ID',
    SText21: '发送验证邮件',
    SText22: '管理我的设备',
    SText23: '登录过的设备',
    SText24: '注销登录',
    SText25: '是否从下列设备登出',
    SText26: '密码已修改.',
    SText27: '请再次登录',
    SText28: 'Automatically enter the sign in page after',
    SText29: 's',
    SText30: '登录',
    SText31: '验证账号',
    SText32: '设置新账号',
    SText33: '等待',
    SText34: '再次发送',
    SText35: '你的新账号设置成功',
    SText36: 'Please sign in with',
    SText37: 'Automatically enter the sign in page after',
    SText38: '地址',
    SText3811: '地址',
    SText39: '地址标签',
    SText40: '新地址',
    SText41: '确认删除？',
    SText42: '系统默认',
    SText43: '自定义标签',
    SText44: '新建标签',
    SText45: '确认删除',
    SText46: '标签 ?',
    SText47: '创建自定义标签。',
    SText48: '标签名称',
    SText49: '合作伙伴',
    SText50: '标签',
    SText51: '联系人',
    SText52: '姓名',
    SText53: '电话',
    SText54: '提单格式',
    SText55: '联系人',
    SText56: '管理员',
    SText57: '起运港业务',
    SText58: '目的港业务',
    SText59: '财务信息',
    SText60: '编辑联系人',
    SText61: '员工',
    SText62: '或填写',
    SText63: '姓',
    SText64: '名',
    SText65: '邮箱',
    SText66: '国家或地区',
    SText67: '手机',
    SText68: '公司名称',
    SText69: '公司名称',
    SText70: '中文公司名称',
    SText71: '公司地址',
    SText72: '公司信息',
    SText73: '公司名称 (英文)',
    SText74: '公司名称 (中文)',
    SText75: '增值税号',
    SText76: '银行',
    SText77: '开户账号',
    SText78: '失效',
    SText79: '已通过',
    SText80: '已锁定',
    SText81: '已失效',
    SText82: '新增',
    SText83: '开票抬头',
    SText84: '发票寄送地址',
    SText85: '银行名称',
    SText86: '新增',
    SText87: '开票信息',
    SText88: '发票抬头',
    SText89: '银行名称',
    SText90: '账号',
    SText91: '我的账号',
    SText92: '我的员工',
    SText93: '我的合作伙伴',
    SText94: '跟踪',
    SText95: '通知人：',
    SText96: '选择通知人',
    SText97: '通知人快速浏览',
    SText98: '合作伙伴',
    SText99: '邀请中',
    SText100: '已邀请',
    SText101: '已屏蔽',
    SText102: '邀请',
    SText103: '合作伙伴名称 & ID',
    SText104: '邀请日期',
    SText105: '角色',
    SText106: '取消邀请',
    SText107: '重新发送邀请',
    SText108: '发送邀请',
    SText109: '编辑合作伙伴',
    SText110: '发送合作伙伴邀请',
    SText111: '合作伙伴名称',
    SText112: '账户ID',
    SText113: '合作伙伴角色',
    SText114: '发送',
    SText115: 'Do you want to cancel the invitation of',
    SText116: '默认密码',
    SText117: '更改默认密码',
    SText118: '所有员工在第一次登录时请使用默认密码登录。',
    SText119: '登录后，可在账户管理中，将自主修改密码',
    SText120: '正常',
    SText121: '新增员工',
    SText122: '重置密码',
    SText123: '验证账号',
    SText124: '关闭账号',
    SText125: '复制成功',
    SText126: '选择运输类型',
    SText127: 'Features not developed',
    SText128: '请填写 Shipper',
    SText129: '长度在 5 到 200 个字符',
    SText130: '请填写 Consignee',
    SText131: '请填写 NotifyParty',
    SText132: 'Please input container',
    SText133: '请填写品名',
    SText134: 'Please do not fill in negative numbers',
    SText135: '请填写总体积',
    SText136: '请填写总重量',
    SText137: '请填写总数量',
    // SText138:'请填写起运港',
    SText139: '请选择目的港或卸货港',
    SText140: '请选择货物类型',
    SText141: '编辑订舱信息',
    SText142: '请填写模板名称',
    SText143: '请填写模板名称',
    SText144: '哎呦出错了！',
    SText145: '账户登出成功',
    SText146: '编辑地址',
    SText147: '新增地址',
    SText148: '长度最多 30 个字符',
    SText149: '删除成功',
    SText150: '请填写名称',
    SText151: '保存成功',
    SText152: '请选择合作伙伴',
    SText153: '请选择地址标签',
    SText154: '长度最多 20 个字符',
    SText155: '请填写手机',
    SText156: '请填写正确的电话',
    SText157: '请填写地址',
    SText158: '长度最多 150 个字符',
    SText159: '请选择城市',
    SText160: '请选择省或州',
    SText161: '请选择国家或地区',
    SText162: '只能数字',
    SText163: '不可有空格',
    SText164: '长度在 1 到 20 个字符',
    SText165: '请填写姓',
    SText166: '请填写名',
    SText167: '请填写邮箱',
    SText168: '电子邮件地址无效',
    SText169: '长度在 1 到 50 个字符',
    // SText170:'Please input Mobile',
    // SText171:'Please input Phone',
    SText172: '长度在 5 到 50 个字符',
    SText173: '长度在 5 到 150 个字符',
    // SText174:'Please select country or region',
    // SText175:'Please select state',
    // SText176:'Please select city',
    SText177: '请填写邮编',
    SText178: '请填写英文公司名称',
    SText179: '请填写中文公司名称',
    SText180: '请填写地址',
    SText181: '长度在 0 到 20 个字符',
    SText182: '请填写开票抬头',
    SText183: '请填写增值税号',
    SText184: '请填写银行名称',
    SText185: '请填写银行账号',
    SText186: '屏蔽成功',
    SText187: '长度介于8到18个字符之间,必须包含大小写字母和数字，不能包含特殊字符',
    SText188: '请填写密码',
    SText189: '请输入其他的邮箱地址',
    SText190: '请填写账号',
    SText191: '长度最多 50 个字符',
    SText192: '请输入正确的邮箱地址',
    SText193: '新增员工信息',
    SText194: '确定重置 ',
    SText197: '的密码？',
    SText195: '确定验证 ',
    SText198: '的账号？',
    SText196: '确定关闭 ',
    SText199: '确定恢复 ',



    NText10: '标题',
    NText9: '创建日期',
    NText8: '收件人',
    NText7: '所有',
    NText6: '已读',
    NText5: '未读',
    NText4: '尊敬的',
    NText3: '欢迎使用Link Me',
    NText1: 'You have signed up sucessfully. Your Account ID is:',
    NText2: '我们的运营团队将会在48小时内与您联系。',
    NText11: 'Create time',
    NText12: 'Send by',
    NText13: '请您点击以下链接，完成注册邮箱的验证。',
    NText14: 'Confirm',
    NText15: '如点击链接无法正常弹出验证窗口，请将如下链接复制到你的浏览器中。',
    NText16: '请在24小时内完成邮箱验证，否则链接将会失效。',
    NText17: 'To get a new verification E-mail, visit',
    NText18: '您将需要返回Link Me用户管理功能，重新触发验证邮件。',
    NText19: 'Line Me 运营团队',
    NText20: 'Your Link Me BMS account has been set up. Your Account ID is:',
    NText21: 'hours',
    // NText22:'We heard that you lost your Link Me Account ID password. Sorry about that!',
    NText23: "请点击如下链接，完成密码重置。",
    NText24: 'Reset password',
    NText25: '您将需要返回Link Me用户管理功能，重新进行密码重置请求',
    NText26: 'You changed your password at',
    // NText27:"If you didn't, please reset your account password immediately.Your account may have been compromised.",
    NText28: "如果这不是您的操作，有可能您的账号已经被他人窃取，请尽快与Link Me客服去的联系。",
    NText29: 'on',
    NText30: '您正在Link Me账户管理中，更改你账号绑定的邮箱。',
    NText31: '请使用如下动态校验码：',
    NText32: 'This code will be expired in',
    NText33: 'If you did not ask for verification, please ignore this e-mail.',
    NText34: '你的账户已经成功更新。',
    NText35: '新账号：',
    NText36: 'If you did not ask for changing Account ID, please ignore this e-mail.',
    NText37: 'We noticed that your Link Me Account ID failed to sign in for many times Since',
    NText38: 'Do you forgot your password? You can use following link to reset your password.',
    NText39: 'If you are not trying to log in to your account.Please keep your account safe.',
    NText40: 'invites you to be an online partner as',
    NText49: '接受',
    NText48: '拒绝',
    NText47: 'invites you to be an online partner as',
    NText46: 'We noticed that you are not our Link Me BMS user yet. If you are willing to accept the invitation, Please follow the steps below',
    NText45: '已接受邀请。',
    NTextCompanynameen: '公司名称（英文）：',
    NTextCompanynamecn: '公司名称（中文）：',
    NTextAccount: 'Account ID：',
    NTextRole: '角色：',
    NText44: 'is your online partner as',
    NText43: '拒绝您在Link Me平台的伙伴邀请。',
    NText42: 'Welcome on board',
    NText41: '运营团队已经对您的注册信息完成了审核。',
    NText51: '所有功能均已开放。',
    NText52: '您有一份新的报价单，请查收。询价单号：',
    NText53: '询价单号：',
    NText54: '询价单号：',
    NText541: 'Link Me 运营团队更新了您的订单。',
    NText55: 'related Quotation ID:',
    NText56: '。报价已被更新，请查收。',
    NText57: 'will be expired on',
    NText58: 'Link Me运营团队为您提交一份询价请求。',
    NText59: '您的 Link Me 伙伴',
    NText50: '给您共享了一封报价，请查收。',
    NText60: '您的共享报价。',
    NText69: '询价单：',
    NText691: '订 单：',
    NText68: '您的订单已经审核通过，正在等待您的授权出运许可。',
    NText681: '您的订单报价已经完成，正在等待您的授权出运许可。',
    NText67: 'Your booking is shipping.',
    NText671: '您可以通过运单号，前往 Link Me 查询您托运货物的最新航程信息。',
    NText66: '订单号：',
    NText65: '.Shipment ID:',
    NText64: 'Link Me运营团队为您提交一份订单请求。',
    NText63: '订单编号：',
    NText62: 'According to your requirement, We modified',
    NText61: 'Link Me 运营团队更新了您的订单。同时，由于您的更新请求与之前报价单已经不再匹配。Link Me 为您的订单生成了一份新的询价请求。',
    NText70: 'Linked Quote ID:',
    NText71: '更新日期：',
    NText72: '截止有效期',
    NText73: '报价单预览',
    NText74: 'ID：',
    NText75: '更新日期：',
    NText76: '运抵日期：',
    // NText77:'截止有效期:',
    NText78: '费用项',
    NText79: '单价',
    NText80: '数量',
    NText81: '币种',
    NText82: '条款',
    NText83: '任务日志',
    NText84: '详细',
    NText85: 'Sharing quotation',
    NText86: '拒绝',
    NText87: '线下报价',
    NText88: '货物信息',
    NText89: 'Type & Status',
    // NText90:'Last update Date',
    NText91: '任务 ID',
    NText92: '相关联 ID',
    NText93: '更新日期',
    NText94: '立即处理',
    NText95: '不能为空',
    NText96: '请选择箱型',
    NText97: '请填写通知人',
    NText98: '请选择贸易条款',
    // NText99:'Please select trade clause',
    NText100: '请选择启运港',
    NText101: '请选择货好日期',
    NText102: '请填写提货地址',
    NText103: '请填写货值',
    NText104: '请选择币种',
    NText105: '请选择仓库增值服务',
    NText106: '请选择卸货港',
    NText107: '请选择交货日期',
    NText108: '请填写交货地址',
    // NText109:'请选择货物类型',
    NText110: '请选择清关保险种类',
    // NText111:'Please input total quantity',
    // NText112:'Please input total volume',
    // NText113:'Please input total weight',
    // NText114:'Please select incotermsType',
    // NText115:'Please select trade clause',
    NText116: '请填写合作伙伴名称',
    NText117: '请填写账号 ID或邮箱',
    NText118: '请选择合作伙伴角色',
    // NText119:'Please select country',
    NText120: '请填写手机',
    NText121: 'Shipment',
    NText122: 'MBL No.',
    NText123: 'Carrier',
    NText124: 'Vessel & Voyage',
    NText125: 'HBL No.',
    NText126: 'Incoterm',
    NText127: 'Shipment Chassis Days',
    NText128: 'Shipment Per Diem Days',
    NText129: 'PO Reference',
    NText130: 'Cargo Release Type',
    NText131: 'R.F Cargo',
    NText132: '箱号',
    NText133: '封号',
    NText134: 'Mile stone',
    NText135: '当前状态',
    NText136: '暂无数据',
    NText137: '数量',
    NText138: '描述',
    NText139: 'Last free day',
    NText140: '下载',
    NText141: 'Shipment ID',
    NText142: '运输方式',
    NText143: 'Notify party',
    NText144: 'ETA',
    NText145: 'ETD',
    NText146: '查看',
    NText147: '编辑员工信息',
    NText148: '路径',
    NText149: '船名&航次',
    NText150: '中转港',
    NText151: '跟踪',
    NText152: 'Results found',
    NText153: '预计',
    // NText154:'Date not found',
    NText155: 'Dashboard',
    NText156: '新报价',
    NText156V: '报价',
    NText157: '待确认',
    NText158: '立即处理',
    NText159: 'Coming soon',
    NText160: '新运单',
    NText161: 'Volum',
    NText162: 'Cost',
    NText163: '选择照片',
    NText164: '头像预览',
    NText165: 'OK',
    NText166: '图片类型要求：jpeg、jpg、png',
    NText167: '图片大小要求：< 5MB',
    NText168: '图片分辨率要求：200px*200px',
    NText169: '查看更多消息',
    NText170: '暂无消息',
    NText171: '文件',
    NText172: 'Link',
    NText173: 'related chatting records',
    NText174: '在线聊天',
    NText175: '设置',
    NText176: '是否确定登出?',
    NText177: '启运港',
    NText178: '提货地址',
    NText179: '请选择卸货港',
    NText180: '目的地地址',
    NText181: '[可选项]',
    NText182: '增值服务',
    NText183: '请选择增值服务',
    // NText184:'请选择Bond类型',
    NText185: 'Please select activity zone',
    NText186: 'PO No.',
    NText187: '托盘',
    NText188: '浏览询价单',
    NText189: '新询价',
    NText190: '您的询价还未完成。',
    NText191: '是否离开？当前询价信息将会丢失。',
    NText192: '离开',
    NText193: '无服务',
    NText194: '询价时间',
    NText195: '加急',
    NText196: '回复时间',
    NText197: '分享',
    NText198: '依据报价单创建',
    NText199: '相关运价要素在定舱位中将会被锁定',
    NText200: '例如',
    NText201: '启运港 / 卸货港 / 服务 / 箱型箱量',
    NText202: '继续',
    NText203: '保密条款',
    NText204: '公开',
    NText205: '启运港费用保密',
    NText206: '运费&目的港运费保密',
    NText207: '所有费用都保密',
    NText208: '分享运价',
    NText209: '结束日期',
    NText300: '开始日期',
    NText301: '回复日期',
    NText302: '清除',
    NText303: 'eDocs',
    NText304: '是否接受报价？',
    NText305: '发票',
    NText306: '提单',
    NText307: '其他',
    NText308: '我的文件',
    NText309: '系统文件',
    NText310: '文件名',
    NText311: '文件状态',
    NText312: '有效',
    NText313: '无效',
    NText314: '询价单模板',
    NText315: '起运港费用总计',
    NText316: '运费总计',
    NText317: '目的港费用总计',
    NText318: '费用名称',
    NText319: '备注',
    NText320: '可能产生费用',
    NText321: '新默认密码',
    NText322: '文件名',
    NText323: '成功',
    NText324: '错误',
    NText325: '登出',
    NText326: '模板编辑',
    NText327: '接受邀请',
    NText328: '合作伙伴名称',
    NText329: '账号',
    NText330: '合作伙伴角色',
    NText331: '取消',


    UserGuide: '用户引导',
    Section: '章节',
    Start: '开始',
    dontShow: '下次不再显示',
}
